import { getBrandAuthConfig } from '../../Common/utils/auth/getBrandAuthConfig';
import { webAuth } from '../../Common/utils/auth/getAuth';
import * as Sentry from '@sentry/react';

export const usePasswordlessComplete = () => {
  const { audience } = getBrandAuthConfig();

  const completePasswordless = async (
    email: string,
    verificationCode: string,
  ): Promise<boolean> => {
    let formURL = `https://${window.location.host}/auth-callback`;
    if (window.location.hostname === 'localhost') {
      formURL = `http://${window.location.host}/auth-callback`;
    }

    return new Promise((success) =>
      webAuth.passwordlessLogin(
        {
          connection: 'email',
          email: email.toLowerCase().trim(),
          verificationCode: verificationCode.trim(),
          audience,
          scope: 'openid profile email offline_access',
          redirectUri: formURL,
        },
        function (err) {
          if (err) {
            console.error(err);

            if (
              err &&
              err?.description &&
              err?.description !== 'Wrong email or verification code.'
            ) {
              Sentry.captureException(err);
            }

            success(false);
          } else {
            success(true);
          }
        },
      ),
    );
  };

  return { completePasswordless };
};
