import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Button, Modal, NativeBaseProvider, Text, VStack } from 'native-base';
import { RecoilRoot, useRecoilValue } from 'recoil';
import WebApp from './WebApp/WebApp';
import * as Sentry from '@sentry/react';
import { theme } from './WebApp/Common/theme/ThemeProvider';
import { loadEwayECrypt } from './WebApp/Common/utils/eway';
import { isDoctorPortal } from './WebApp/Common/utils/isDoctorPortal';
import { LogBox, Platform } from 'react-native';
import { DevSwitcher } from './WebApp/DevSwitcher/components/DevSwitcher';
import { devStateIsLocalHost } from './WebApp/DevSwitcher/state/devState';
import { initScripts } from './WebApp/Common/utils/tracking/initScripts';
import { initCss } from './WebApp/Common/theme/initCss';
import { AuthProvider } from './WebApp/Common/providers/AuthProvider';
import { RouterProvider } from './WebApp/Common/providers/RouterProvider';
import { authenticationStateAtom } from './WebApp/Common/state/authenticationState';
import { useGraphQLClient } from './WebApp/Common/hooks/useGraphQLClient';
import { useAuthState } from './WebApp/Common/hooks/useAuthState';
import { useLogOut } from './WebApp/Common/hooks/useLogOut';
import Loading from './WebApp/Common/components/Loading';
import { withLDWrapper } from './WebApp/withLDWrapper';
import { MobileAuth } from './WebApp/MobileAuth/MobileAuth';
import { useFonts } from './WebApp/Common/hooks/useFonts';
import { useCustomerIO } from './WebApp/Common/hooks/useCustomerIo';
import { useRouting } from './WebApp/Common/hooks/useRouting';
import { PushNotificationsProvider } from './WebApp/Common/providers/PushNotificationsProvider';
import { FaceIDProvider } from './WebApp/Common/providers/FaceIDProvider';
import { useIsMobile } from './WebApp/Common/hooks/useIsMobile';
import DoctorLogin from './WebApp/Login/components/DoctorLogin';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import './WebApp/Common/localise/i18n';
import { ToastProvider } from './WebApp/Common/providers/ToastProvider';
import { ConfigProvider } from './WebApp/Common/providers/ConfigProvider';
import { ExperimentProvider } from './WebApp/Common/providers/ExperimentProvider';
import './WebApp/Common/constants/mixpanel';

if (__DEV__) {
  loadDevMessages();
  loadErrorMessages();

  LogBox.ignoreLogs(['Please pass alt prop to Image component']); // Ignore log notification by message
  LogBox.ignoreAllLogs(true); //Ignore all log notifications

  const originalConsoleError = console.error;

  console.warn = (message, ...args) => {
    if (
      typeof message === 'string' &&
      (message.includes('Please pass alt prop to Image component') ||
        message.includes(
          'If you do not provide children, you must specify an aria-label for accessibility',
        ))
    ) {
      return;
    }
    originalConsoleError.apply(console, [message, ...args]);
  };
}

const DoctorVoiceProvider = React.lazy(
  () => import('./WebApp/DoctorVoice/components/DoctorVoiceProvider'),
);

const DoctorNotificationsProvider = React.lazy(
  () => import('./WebApp/Common/providers/NotificationsProvider'),
);

loadEwayECrypt();
initScripts();
initCss();

function AppWeb() {
  const { isLoading: isAuthLoading, isAuthenticated } = useRecoilValue(authenticationStateAtom);
  const { isLoading: isFontsLoading } = useFonts();

  if (isAuthLoading || isFontsLoading) return <Loading />;

  if (Platform.OS !== 'web' && !isAuthenticated) {
    return <MobileAuth />;
  }

  return (
    <>
      <WebApp />
      {devStateIsLocalHost && <DevSwitcher />}
    </>
  );
}

function AppDoctorPortal() {
  const {
    isExpired,
    isLoading: isAuthLoading,
    isAuthenticated,
  } = useRecoilValue(authenticationStateAtom);
  const { logOut } = useLogOut();

  const { isNative } = useIsMobile();

  const { pathname } = useRouting();

  if (isAuthLoading) {
    return (
      <NativeBaseProvider theme={theme}>
        <Loading />
      </NativeBaseProvider>
    );
  }

  if (!isAuthenticated && isNative) {
    return (
      <NativeBaseProvider theme={theme}>
        <DoctorLogin />
      </NativeBaseProvider>
    );
  }

  return (
    <ToastProvider>
      <DoctorVoiceProvider>
        <DoctorNotificationsProvider>
          <ConfigProvider>
            <NativeBaseProvider theme={theme}>
              <WebApp />

              {devStateIsLocalHost && <DevSwitcher />}

              {isExpired && pathname !== '/signin' && (
                <Modal isOpen={true} size="xl">
                  <Modal.Content maxWidth="350">
                    <Modal.Header>Session Expired</Modal.Header>
                    <Modal.Body>
                      <VStack space={2}>
                        <Text>Your session has expired and you will need to re-login again.</Text>

                        <Text>Also ensure you are connected via CloudFlare WARP</Text>
                      </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button flex="1" onPress={logOut}>
                        Login Again
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal>
              )}
            </NativeBaseProvider>
          </ConfigProvider>
        </DoctorNotificationsProvider>
      </DoctorVoiceProvider>
    </ToastProvider>
  );
}

const RecoilWrapper = () => {
  return (
    <RecoilRoot>
      <AppWrapper />
    </RecoilRoot>
  );
};

const AppWrapper = function () {
  const graphClient = useGraphQLClient();

  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider>
        <ApolloProvider client={graphClient}>
          <AuthProvider>
            <PushNotificationsProvider>
              <ConfigProvider>
                <AppOuter />
              </ConfigProvider>
            </PushNotificationsProvider>
          </AuthProvider>
        </ApolloProvider>
      </RouterProvider>
    </Suspense>
  );
};

const AppOuter = function () {
  useAuthState();
  useCustomerIO();

  if (isDoctorPortal()) {
    return <AppDoctorPortal />;
  }

  return (
    <NativeBaseProvider theme={theme}>
      <FaceIDProvider>
        <ExperimentProvider>
          <AppWeb />
        </ExperimentProvider>
      </FaceIDProvider>
    </NativeBaseProvider>
  );
};

export default Sentry.withErrorBoundary(withLDWrapper(<RecoilWrapper />), {
  showDialog: false,
});
