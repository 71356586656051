import Pusher from 'pusher-js';
import { graphql } from '../../__generated__';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { log } from '../Logging';
import { isDev } from '../hooks/useIsDev';

const pushAuthorize = graphql(`
  mutation pusherAuth($channel: String, $socketId: String) {
    pusherAuthorize(channel: $channel, socketId: $socketId)
  }
`);

export const pusher = new Pusher('cc4b858a645405ee8a08', {
  cluster: 'ap4',
  forceTLS: true,
  authorizer: ({ name }) => ({
    authorize: async (socketId, callback) => {
      try {
        // @ts-ignore
        if (window.apolloClient) {
          const result = await (window.apolloClient as ApolloClient<NormalizedCacheObject>).mutate({
            mutation: pushAuthorize,
            variables: {
              channel: name,
              socketId: socketId,
            },
          });

          const pushedAuth = JSON.parse(result.data?.pusherAuthorize);

          callback(false, pushedAuth);
        } else {
          callback(true, null);
        }
      } catch (e) {
        console.log(e);

        callback(true, null);
      }
    },
  }),
});

export const pusherSubscribeObject = (
  channelID: string,
  eventName: string,
  onEvent: (payload: any) => void,
) => {
  const channelSuffix = isDev() ? 'private-dev' : 'private';

  const formattedChannelName = `${channelSuffix}-${channelID.replace(/\W/g, '')}`;

  const channel = pusher.subscribe(formattedChannelName);

  channel.bind('connected', () => log(`[Realtime] Subscribed to ${formattedChannelName}`));
  channel.bind('disconnected', () => log(`[Realtime] Disconnected from ${formattedChannelName}`));

  channel.bind(eventName, function (payload: any) {
    log(`[Realtime] Event for ${eventName}`);
    onEvent(payload);
  });

  return {
    unsubscribe: () => {
      pusher.unsubscribe(channelID);
    },
    send: (eventName: string, eventData: any) => channel.trigger(eventName, eventData),
  };
};
