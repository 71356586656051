import { Platform } from "react-native";

export function loadEwayECrypt() {
    if (Platform.OS !== 'web') {
        return;
    }

    var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
    t.async = true;
     t.src = 'https://secure.ewaypayments.com/scripts/eCrypt.min.js';
    s.parentNode.insertBefore(t, s);
}

