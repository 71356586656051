import { Box } from 'native-base';
import { marked } from 'marked';
import React, { useMemo } from 'react';

export function FormFieldMarkdown({ text }: { text: string }) {
  const html = useMemo(() => marked(text, { mangle: false, headerIds: false }), [text]);

  return (
    <Box bg={'white'} borderRadius={'sm'} p={2} color={'black'}>
      <div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
    </Box>
  );
}
