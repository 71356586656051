import React, { useMemo } from 'react';
import { Box, Text, Pressable, HStack, VStack } from 'native-base';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons/faXmarkCircle';
import emailMisspelled, { top100 } from 'email-misspelled';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import formatStringByPattern from 'format-string-by-pattern';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import dayjs from 'dayjs';
import { faWarning } from '@fortawesome/pro-solid-svg-icons/faWarning';
import { Input } from './Input';
import { Icon } from '../../Common/components/Icon';

const emailChecker = emailMisspelled({ domains: top100, maxMisspelled: 1 });

export const useIsEmailPrivacy = () => {
  const privacyDomains = ['privaterelay.appleid.com'];

  const checkEmail = (email) => {
    try {
      if (!email) return false;

      const splitEmail = email?.split('@')[1];

      return privacyDomains.includes(splitEmail);
    } catch (e) {
      return false;
    }
  };

  return {
    checkEmail,
  };
};

export const FormFieldText = ({
  value,
  id,
  setFormField,
  isError,
  subType = 'text',
  loading,
  isValid,
  setFocused,
  large,
  autocomplete,
  placeholder,
}) => {
  const isEmail = autocomplete === 'email';

  const emailSuggestions = useMemo(() => {
    if (!isEmail) return null;
    return emailChecker(value);
  }, [isEmail, value]);

  const { checkEmail } = useIsEmailPrivacy();

  const proxyValue = () => {
    if (id === 'medicareexp') {
      const valueFormatted = formatStringByPattern('99/9999', value ?? '');

      return valueFormatted;
    }

    return value ?? '';
  };

  const emailAlert = () => {
    if (!isEmail || !checkEmail(value)) return null;

    return (
      <Box bg={'yellow.200'} p={2} borderRadius={'sm'} mt={2}>
        <Text fontSize={'xs'}>
          Hidden eMail Address - Ensure you use your real eMail address so your doctor can get in
          contact with you. This avoids any email delivery issues.
        </Text>
      </Box>
    );
  };

  const dateValues = () => {
    if (id !== 'from') {
      return (
        <Input
          value={proxyValue()}
          size="lg"
          bg={'white'}
          type={subType === 'password' ? 'password' : 'text'}
          isDisabled={loading}
          multiline={large}
          placeholder={placeholder}
          placeholderTextColor={'gray.500'}
          autoComplete={autocomplete}
          {...(isEmail && {
            autoCapitalize: 'none',
          })}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          style={{
            ...(large && {
              height: 80,
            }),
            opacity: loading ? 0.5 : 1,
          }}
          InputRightElement={
            <Box p={2}>
              {isError && <Icon icon={faXmarkCircle} size="sm" color="red" />}
              {isValid && <Icon icon={faCheckCircle} size="sm" color="green" />}
            </Box>
          }
          onChangeText={(text) => setFormField(id, text)}
        />
      );
    }

    let start = dayjs().startOf('day').subtract(1, 'day');
    let end = dayjs().startOf('day').add(2, 'day');

    let dates = [];

    while (start.isBefore(end)) {
      const newDate = dayjs(start);

      const isToday = dayjs().isSame(newDate, 'day');
      const isYesterday = dayjs().subtract(1, 'day').isSame(newDate, 'day');

      let title = newDate.format('ddd D/M');

      if (isToday) {
        title = `Today ${newDate.format('D/M')}`;
      } else if (isYesterday) {
        title = `Yesterday ${newDate.format('D/M')}`;
      }

      dates.push({
        date: newDate.format('DD/MM/YYYY'),
        title: title,
        dateObj: newDate,
      });

      start = newDate.add(1, 'day');
    }

    const showAlert = value && !dayjs().isSame(dayjs(value, 'DD/MM/YYYY'), 'day');

    return (
      <>
        <HStack space={2} w={'100%'} mb={2}>
          {dates.map((date) => {
            let isDateSelected = value === date.date;

            return (
              <Pressable flex={1} key={date.title} onPress={() => setFormField(id, date.date)}>
                <VStack
                  bg={isDateSelected ? '#8bd7f6' : 'white'}
                  space={1}
                  borderWidth={1}
                  borderRadius={'md'}
                  borderColor={'gray.200'}
                  p={2}
                >
                  <Icon size={'sm'} icon={faCalendar} />
                  <Text
                    textAlign={'center'}
                    fontSize={'xs'}
                    fontWeight={isDateSelected ? '600' : 'normal'}
                  >
                    {date.title}
                  </Text>
                </VStack>
              </Pressable>
            );
          })}
        </HStack>

        {showAlert && (
          <Box p={2} bg={'yellow.100'} borderRadius={'md'}>
            <Text fontSize={'xs'}>
              <Icon icon={faWarning} /> Your partner doctor may change the start dates of your
              medical certificate to today unless you can provide supporting evidence (eg hospital
              discharge papers, medical appointment letter). You can supply this when you consult
              with your partner doctor. Australian doctors are not able to backdate medical
              certificates as these are legal docuemnts.
            </Text>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {dateValues()}

      {emailAlert()}

      {emailSuggestions?.map((email) => (
        <Pressable key={email.corrected} onPress={() => setFormField(id, email.corrected)}>
          <Text pt={2} fontWeight={600}>
            <Icon size={'sm'} icon={faCircleExclamation} /> Did you mean {email.corrected}?
          </Text>
        </Pressable>
      ))}
    </>
  );
};
