import React, { Suspense } from 'react';
import { useBrandName } from '../Common/hooks/useBrand';
import { Box, StatusBar } from 'native-base';
import Loading from '../Common/components/Loading';
import { BannerBox } from '../Common/components/Banner';
import { Navigate, Route, Routes } from 'react-router-native';
import Login from '../Login/components/Login';
import { MobileOnboarding } from './MobileOnboarding';


export const MobileAuth = () => {
  const { backgroundColor } = useBrandName();

  return (
    <Box backgroundColor={backgroundColor} height={'100%'}>
      <StatusBar
        backgroundColor={backgroundColor}
        barStyle="dark-content"
      />
      <Suspense fallback={<Loading />}>
        <BannerBox />

        <Routes>
          <Route element={<MobileOnboarding />} path="/" />
          <Route element={<Login />} path="/signin" />

            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Suspense>
    </Box>
  );
}