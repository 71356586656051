import { Button, Input, Text, VStack } from 'native-base';
import { Alert as AlertRN } from 'react-native';
import React, { FC, useEffect, useState } from 'react';

type Props = {
  onSubmitCode: (code: string) => Promise<void>;
  reset: () => void;
};

export const PasswordlessLoginStep2: FC<Props> = ({ onSubmitCode, reset }) => {
  const [code, setCode] = useState('');

  const shownCode = code.substring(0, 6);

  const onSubmit = async () => {
    if (code.length < 6) {
      AlertRN.alert('Error', 'Please enter a valid code');
    } else {
      await onSubmitCode(code);
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      onSubmitCode(code);
    }
  }, [code.length]);

  return (
    <>
      <VStack space={2} p={4} mt={4} borderRadius="md" bg="white">
        <Text mb={2} fontWeight={600} fontSize="md" textAlign="center" color={'loginLabel'}>
          Check your emails and enter the code sent to sign in. Codes can be used once and are valid
          for 5 minutes.
        </Text>

        <Input
          fontFamily={'mono'}
          fontSize={50}
          mb={2}
          value={shownCode}
          onChangeText={setCode}
          onSubmitEditing={onSubmit}
          placeholder="000000"
          keyboardType={'number-pad'}
          letterSpacing={'2xl'}
          textAlign={'center'}
        />

        <Button size={'lg'} mb={2} onPress={onSubmit}>
          Sign in
        </Button>

        <Button onPress={reset} variant={'outline'}>
          Start Again
        </Button>
      </VStack>
    </>
  );
};
