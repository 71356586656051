import { useServices } from '../../Services/hooks/useServices';
import { useNetworkStatus } from './useNetworkStatus';
import { usePersistantFlow } from './usePersistantFlow';
import { PricingTier, ServiceOption } from '../../__generated__/graphql';
import currency from 'currency.js';

export const useQuickStartService = () => {
  const { services } = useServices();
  const { pricingTiers } = useNetworkStatus();

  const { startFlow } = usePersistantFlow();

  const startService = (serviceID: string, formId?: string, formValues?: any) => {
    const service = services?.find((a: ServiceOption) => a.id === serviceID);
    const price = pricingTiers?.find((a: PricingTier) => a.id === service.pricing);

    startFlow(price, service, formId, formValues);
  };

  const pricingForService = (serviceID: string) => {
    const matchingPrice = pricingTiers?.find((a: any) => a.id === serviceID);

    if (!matchingPrice) return '';

    return `$${matchingPrice.amount}`;
  };

  return {
    startService,
    pricingForService,
  };
};
