import { Box, VStack } from 'native-base';
import React from 'react';
import Loading from '../../Common/components/Loading';
import { useBrand } from '../../Common/hooks/useBrand';
import { useIsMobile } from '../../Common/hooks/useIsMobile';
import { Dimensions } from 'react-native';

export function WebLayoutLeafDoctors({
  borderBottomWidth,
  borderBottomColor,
  children,
  bg,
  pb,
  loading = false,
  spacing = 4,
  p = 4,
  minH,
  maxW = 900,
  py = 2,
  outsideComponent,
}) {
  const windowHeight = Dimensions.get('window').height;

  return (
    <Box alignItems="center" bg={bg} w="100%" minH={minH} overflow={'hidden'} flex={1}>
      {outsideComponent}

      <Box w="100%" height={"100%"} minH={windowHeight * 0.7} maxW={1100} p={p} py={py} pb={pb}>
        <VStack
          pl={1}
          pr={1}
          borderBottomColor={borderBottomColor}
          borderBottomWidth={borderBottomWidth}
          space={spacing}
          flex={1}
        >
          {loading && (
            <Box alignItems={'center'}>
              <Loading />
            </Box>
          )}
          {!loading && children}
        </VStack>
      </Box>
    </Box>
  );
}
