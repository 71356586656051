import { Box, Button, VStack, Text } from 'native-base';
import React, { useRef } from 'react';
import Signature from '@uiw/react-signature';

export function FormFieldSignature({ value, id, setFormField }) {
  const timerDebounce = useRef();

  const $svg = useRef(null);
  const clearSignature = () => {
    $svg.current?.clear();

    setTimeout(() => setFormField(id, null), 200);
  };

  const downloadImage = (): Promise<string> => {
    return new Promise((success, error) => {
      try {
        const svgelm = $svg.current?.svg?.cloneNode(true) as SVGSVGElement;

        var svgData = new XMLSerializer().serializeToString(svgelm);

        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');

        var img = document.createElement('img');
        img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(svgData));

        img.onload = function () {
          ctx.drawImage(img, 0, 0);

          success(canvas.toDataURL('image/png'));
        };
      } catch (e) {}
    });
  };

  const onSignatureChange = (e) => {
    if (timerDebounce.current) {
      clearTimeout(timerDebounce.current);
    }

    timerDebounce.current = setTimeout(() => {
      downloadImage().then((signature) => setFormField(id, signature));
    }, 100);
  };

  return (
    <VStack space={1} alignItems={'center'}>
      <Box
        borderWidth={1}
        bg={'white'}
        borderColor={'gray.200'}
        borderRadius={'sm'}
        style={{
          width: '305px',
          height: '155px',
        }}
      >
        <div style={{ width: '300px', height: '150px' }}>
          <Signature
            ref={$svg}
            onPointer={onSignatureChange}
            fill="black"
            style={{ '--w-signature-background': 'whitesmoke' }}
          />
        </div>
      </Box>

      <Button variant={'outline'} onPress={clearSignature} size={'sm'}>
        Clear Signature
      </Button>
    </VStack>
  );
}
