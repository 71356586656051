import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { FC, PropsWithChildren, useEffect } from 'react';
import { growthbook } from '../constants/growthbook';
import React from 'react';
import { useBrand } from '../hooks/useBrand';

export const ExperimentProvider: FC<PropsWithChildren> = ({ children }) => {
  const brand = useBrand();

  useEffect(() => {
    if (brand === 'leafdoctors') {
      growthbook.init({ streaming: true });
    }
  }, []);

  if (brand !== 'leafdoctors') return children;

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>;
};
