import { isDoctorPortal } from '../isDoctorPortal';
import { brandForDomain } from '../platform/brand';
import Constants from 'expo-constants';

export const getBrandAuthConfig = () => {
  const brand = brandForDomain();

  if (isDoctorPortal()) {
    const config = Constants.expoConfig?.extra?.portal_auth0_web;

    return config ?? {};
  }

  if (brand === 'leafdoctors') {
    return {
      domain: 'auth.leafdoctors.com.au',
      audience: 'https://api.leafdoctors.com.au',
      clientID: 'lHESycyUWH0rWArybLI9rdmMhS1Xsw5R',
    };
  }

  return {
    domain: 'auth-portal.leafdoctors.com.au',
    audience: 'https://api-portal.leafdoctors.com.au',
    clientID: 'nm9c3SJ2nt8ZneOKETzsYKtQjsgo0u61',
  };
};
