import { Pressable, ScrollView, Text, VStack } from 'native-base';
import React, { FC } from 'react';
import { WebLayout } from '../../Layout/components/WebLayout';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import DocumentTitle from 'react-document-title';
import { useBrandName } from '../../Common/hooks/useBrand';
import { useLogin } from '../hooks/useLogin';
import { useRouting } from '../../Common/hooks/useRouting';
import { Icon } from '../../Common/components/Icon';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { SigninWithApple, SigninWithGoogle } from '../../Common/components/SigninWithApple';
import { Form } from '../../Form/components/Form';
import { PasswordlessLogin } from './PasswordlessLogin';

const Login: FC<{}> = () => {
  const { name, auth } = useBrandName();
  const { navigate } = useRouting();

  const hasApple = auth.includes('apple');
  const hasGoogle = auth.includes('google');

  const {
    formSchema,
    onGoogleLogin,
    forgotPassword,
    onAppleLogin,
    loading,
    hasPasswordlessAuth,
    error,
    onSubmit,
    formRef,
  } = useLogin();

  if (hasPasswordlessAuth) {
    return <PasswordlessLogin />;
  }

  return (
    <ScrollView>
      <DocumentTitle title={`Sign In - ${name}`} />

      <Header />

      <WebLayout p={4} maxW={400}>
        <Text textAlign="center" mt={4} fontWeight={600} fontSize="2xl">
          <Icon icon={faLock} /> Sign In
        </Text>

        <Pressable onPress={() => navigate('/services')}>
          <Text mb={2} fontSize="md" textAlign="center">
            Don't have an account? Click to get started
          </Text>
        </Pressable>

        {hasApple && (
          <Pressable onPress={onAppleLogin}>
            <SigninWithApple />
          </Pressable>
        )}

        {hasGoogle && (
          <Pressable onPress={onGoogleLogin}>
            <SigninWithGoogle />
          </Pressable>
        )}

        {(hasGoogle || hasApple) && <Text textAlign={'center'}>Or continue with</Text>}

        {error && <Error show={true} message={error} />}

        <VStack space={2} p={4} borderRadius="md" bg="loginBackground">
          <Form
            formSchema={formSchema}
            loading={loading}
            onSubmit={onSubmit}
            ref={formRef}
            labelColor={'loginLabel'}
            submitButtonTitle={'Sign In'}
          />

          <Pressable onPress={forgotPassword}>
            <Text color={'loginLabel'} fontSize="xs" textAlign="center" mt={4}>
              Forgot your password? Click to reset your password
            </Text>
          </Pressable>
        </VStack>
      </WebLayout>

      <Footer />
    </ScrollView>
  );
};

export default Login;
