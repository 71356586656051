import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';

export function Icon({
  icon,
  color,
  size = 'md',
  style,
}: {
  icon: IconDefinition;
  color?: string;
  size?: SizeProp | 'md';
  style?: FontAwesomeIconProps['style'];
}) {
  const appliedStyle = {
    ...(style ?? {}),
    ...(size === 'md'
      ? {
          width: 20,
          height: 20,
        }
      : {}),
  };

  const appliedSize = size === 'md' ? undefined : size;

  const props = {
    icon,
    color,
    size: appliedSize,
    style: appliedStyle,
  };

  return <FontAwesomeIcon {...props} />;
}
