import React, { FC } from 'react';
import { Box, Button, HStack, Pressable, Text } from 'native-base';
import { useIsMobile } from '../Common/hooks/useIsMobile';
import { useCustomer } from '../Common/hooks/useCustomer';
import { useRouting } from '../Common/hooks/useRouting';
import { Icon } from '../Common/components/Icon';
import { Tabs } from '../Profile/components/LeafDoctors/Tabs';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { Linking, SafeAreaView, View } from 'react-native';
import { SVGImage } from '../Common/components/SvgImage';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import dayjs from 'dayjs';
import hi from '@mobiscroll/react/dist/src/i18n/hi';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';

const LeafDoctorLogo = require('../Assets/LeafDoctors/LD_Logo_Big.svg');

type Props = {
  hideRight?: boolean;
};

export const HeaderLeafDoctors: FC<Props> = ({ hideRight }) => {
  const { isMobile, isNative } = useIsMobile();
  const { navigate, pathname } = useRouting();
  const { signedin } = useCustomer();
  const { top } = useSafeAreaInsets();

  const outerStylings = {
    ...(isNative && {
      width: '100%',
      paddingTop: top,
    }),
    ...(!isNative && {
      marginTop: isMobile ? 5 : 20,
      maxWidth: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    }),
  };

  const innerStylings = {
    ...(isNative && {
      height: 70,
      borderRadius: 10,
      paddingLeft: 14,
      paddingRight: 14,
    }),
    ...(!isNative && {
      height: isMobile ? 75 : 98,
      borderRadius: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: 'white',
      ...(!isMobile && {
        width: '100%',
      }),
      ...(isMobile && {
        marginLeft: 10,
        marginRight: 10,
        width: 'calc(100vw - 20px)',
      }),
    }),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  };

  return (
    <View style={outerStylings}>
      <View style={innerStylings}>
        <Box flex={1} backgroundColor={'red'}>
          <Pressable
            disabled={isNative}
            onPress={() => {
              navigate(signedin ? '/profile' : 'https://leafdoctors.com.au');
            }}
          >
            <SVGImage
              svg={LeafDoctorLogo}
              width={isMobile ? 190 : 251}
              height={isMobile ? 50 : 66}
            />
          </Pressable>
        </Box>

        {!isMobile && signedin && (
          <Box w={340} pt={1} h={20}>
            <Tabs />
          </Box>
        )}

        {!hideRight && !signedin && pathname !== '/signin' && (
          <Button
            onPress={() => {
              navigate(signedin ? '/profile' : '/signin');
            }}
            variant={'outline'}
          >
            {'Sign In'}
          </Button>
        )}

        {hideRight && (
          <Pressable
            onPress={() => Linking.openURL('tel:1300159140')}
            bg={'gray.100'}
            p={2}
            borderRadius={'md'}
            alignItems={'center'}
          >
            <HStack space={2} alignItems={'center'}>
              <Icon color={'gray'} icon={faPhone} size={'md'} />
              <Text fontSize={'xs'} color={'gray'}>
                1300 159 140
              </Text>
            </HStack>
          </Pressable>
        )}

        {signedin && (
          <Button
            ml={isMobile ? 0 : 4}
            size={isMobile ? 'sm' : null}
            leftIcon={<Icon icon={faCalendar} size={'sm'} color={'white'} />}
            onPress={() => {
              navigate('/services');
            }}
          >
            {'Book'}
          </Button>
        )}
      </View>
    </View>
  );
};
