import { gql } from '@apollo/client';

export const customerFragment = gql`
  fragment customerFragment on Customer {
    __typename
    id
    name
    given
    brand
    doctor
    surname
    dob
    completedProfile
    email
    mobile
    gender
    doctorOnline
    doctorApplying
    doctorBio
    doctorImage
    doctorRegistration
    doctorPendingPayment
    address
    suburb
    state
    postcode
    medicalhistory
    medications
    ihi
    medicarenumber
    medicareref
    medicareexp
    hearaboutus
    allergies
    street
    kyc
    internal
    kycType
    kycAddress
    kycName
  }
`;
