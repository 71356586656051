import { Box } from 'native-base';
import React from 'react';
import { useReport } from '../hooks/useReport';
import Loading from '../../Common/components/Loading';
import { Webview } from '../../HeyFellaMobileApp/Common/Webview';

export default function () {
  const { reportURL, loading } = useReport();

  if (loading) return <Loading />;

  return (
    <Box flex={1}>
      <Webview url={reportURL} flex={1} />
    </Box>
  );
}
