import React, { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { Button, Heading, Text, VStack } from 'native-base';
import { FormField } from './FormField';
import { useForm } from '../hooks/useForm';
import { formula, init } from 'expressionparser';
import { useIsMobile } from '../../Common/hooks/useIsMobile';
import { TappableAreaHeight } from '../../HeyFellaMobileApp/Common/TappableAreasConst';

export const Form = memo(
  forwardRef(
    (
      { formSchema, onSubmit, loading, submitButtonTitle = 'Next', labelColor, onSetFormField },
      ref,
    ) => {
      const { form, onPress, setFormField, setupForm, setFormFieldNotes, shouldShowField } =
        useForm(formSchema, onSubmit, loading, onSetFormField);

      useImperativeHandle(ref, () => ({
        setupForm,
        getState: () => form,
      }));

      const { isNative } = useIsMobile();

      const fields = form?.form?.fields
        .filter((field) => {
          return shouldShowField(field) && field.page === form.page;
        })
        .map((field) => {
          return (
            <FormField
              key={field.id}
              submitCount={form.submitCount}
              setFormFieldNotes={setFormFieldNotes}
              notes={form.notes[field.id]}
              setFormField={setFormField}
              value={form.values[field.id]}
              error={form.errors[field.id]}
              labelColor={labelColor}
              loading={loading}
              field={field}
            />
          );
        });

      const buttonsProps = {
        ...(isNative && {
          h: TappableAreaHeight,
        }),
      };

      return (
        <VStack space={4} flex={1}>
          {fields}

          <Button onPress={onPress} variant="form" isLoading={loading} {...buttonsProps}>
            {submitButtonTitle}
          </Button>
        </VStack>
      );
    },
  ),
  (oldProps, newProps) =>
    oldProps.loading === newProps.loading &&
    oldProps?.formSchema?.id === newProps?.formSchema?.id &&
    oldProps?.formSchema?.fields?.length === newProps?.formSchema?.fields?.length,
);
