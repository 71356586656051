import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const usersTimezone = dayjs.tz.guess();

export const useTimezones = () => {
  const [today] = useState(dayjs());

  const usersTime = () => {
    return today.tz(usersTimezone, true);
  };

  const usersTimezoneName = (formatted: boolean) => {
    if (formatted) {
      return usersTimezone.toString().split('/')[1];
    }

    return usersTimezone;
  };

  const convertDateToUser = (time: string | null | undefined): Dayjs | null => {
    try {
      const parsedTime = dayjs
        .tz(time, 'DD/MM/YYYY hh:mm', 'Australia/Brisbane')
        .utc()
        .tz(usersTimezone);

      return parsedTime;
    } catch (e) {
      return null;
    }
  };

  const convertUTCDateToUserISO = (time: string | null | Dayjs | undefined): Dayjs | null => {
    try {
      const parsedTime = dayjs(time).utc().tz(usersTimezone);

      return parsedTime;
    } catch (e) {
      return null;
    }
  };

  const formatDateToUser = (date: string | null | undefined): string => {
    if (!date) return '';

    const convertedTime = convertUTCDateToUserISO(date);

    if (!convertedTime?.isValid()) return '';

    return convertedTime?.format('DD/MM/YYYY') ?? '';
  };

  const brisbaneTime = (): Dayjs => {
    const parsedTime = dayjs().utc().tz('Australia/Brisbane');

    return parsedTime;
  };

  return {
    formatDateToUser,
    usersTimezone,
    usersTimezoneName,
    convertUTCDateToUserISO,
    convertDateToUser,
    brisbaneTime,
    today,
    usersTime,
  };
};
