import React, {useState} from "react";
import { gql, useQuery } from "@apollo/client";
import { contentfulGQLClient} from "../utils/contentful";

export const useNetworkStatus = (polling = true) => {

  const { data, error, loading } = useQuery(
    gql`
      query networkStatus {
        networkStatus {
          id
          __typename
          available
          statusColor
          statusIcon
          statusMessage
          statusMessageHome
          statusMessageOrder
          pricingTiers {
            id
            name
            description
            amount
            amount_per
            available
            statusColor
            statusIcon
            statusMessage
            statusMessageHome
            statusMessageOrder
            __typename
          }
          availableDoctors {
            id
            doctorImage
            name
            __typename
          }
        }
      }
    `,
      {
        fetchPolicy: 'cache-first'
      }
  );

  const [networkDataOverride, setNetworkDataOverride] = useState();

  useQuery(gql`
    query networkStatusCollection {
      networkStatusCollection {
        __typename
        items {
          override
          statusAvailable
          statusMessage
          statusMessageHome
          statusMessageOrder
          __typename
        }
      }
    }
  `, {
    fetchPolicy: 'cache-first',
    client: contentfulGQLClient,
    onCompleted: (data) => {
      const override = data?.networkStatusCollection?.items?.[0];

      if (override && override?.override) {
        setNetworkDataOverride(override);
      }
    }
  })

  return {
    override: !!networkDataOverride,
    statusError: error,
    statusLoading: loading,
    statusColor: data?.networkStatus?.statusColor,
    statusIcon: data?.networkStatus?.statusIcon,
    statusAvailable: networkDataOverride?.statusAvailable ?? data?.networkStatus?.available,
    statusMessage: networkDataOverride?.statusMessage ?? data?.networkStatus?.statusMessage,
    statusMessageHome: networkDataOverride?.statusMessageHome ?? data?.networkStatus?.statusMessageHome,
    statusMessageOrder: networkDataOverride?.statusMessageOrder ?? data?.networkStatus?.statusMessageOrder,
    pricingTiers: data?.networkStatus?.pricingTiers || [],
    availableDoctors: data?.networkStatus?.availableDoctors || [],
  };
};

