import React from 'react';
import { Box, HStack } from 'native-base';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons/faXmarkCircle';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { Icon } from '../../Common/components/Icon';

export const FormFieldDropdown = ({
  value,
  id,
  setFormField,
  isError,
  options,
  loading,
  isValid,
}) => {
  return (
    <HStack
      bg={'white'}
      borderWidth={1}
      overflow={'hidden'}
      borderColor={'rgb(212, 212, 212)'}
      borderRadius={'sm'}
    >
      <select
        value={value ?? ''}
        disabled={loading}
        style={{
          opacity: loading ? 0.5 : 1,
          flex: 1,
          border: 'none',
        }}
        placeholder={'Select a option'}
        onChange={(e) => setFormField(id, e.target.value)}
      >
        <option disabled={true} value={''}>
          Select a option
        </option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <Box p={2} alignItems={'center'} justifyContent={'center'}>
        <Icon icon={faCaretDown} size="sm" color="black" />
      </Box>

      <Box p={2} alignItems={'center'} justifyContent={'center'}>
        {isError && <Icon icon={faXmarkCircle} size="sm" color="red" />}
        {isValid && <Icon icon={faCheckCircle} size="sm" color="green" />}
      </Box>
    </HStack>
  );
};
