import React, { FC, PropsWithChildren } from 'react';
import { useQuery } from '@apollo/client';
import { graphql } from '../../__generated__';
import { Config } from '../../__generated__/graphql';
import { ConfigContext } from '../context/ConfigContext';
import { isDoctorPortal } from '../utils/isDoctorPortal';
import { useBrand } from '../hooks/useBrand';
import { useRecoilValue } from 'recoil';
import { authenticationStateAtom } from '../state/authenticationState';

export const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const brand = useBrand();

  const { isLoading, isAuthenticated } = useRecoilValue(authenticationStateAtom);

  const { data: doctorConfig } = useQuery(
    graphql(`
      query config {
        config {
          brand
          paymentGateway
          paymentGatewayAPIPublicKey
          paymentGatewayID
          afterpay
          __typename
        }
      }
    `),
    { skip: !(isDoctorPortal() && !isLoading && isAuthenticated) },
  );

  const { data: patientConfig } = useQuery(
    graphql(`
      query patientConfig($brand: String) {
        patientConfig(brand: $brand) {
          brand
          paymentGateway
          paymentGatewayAPIPublicKey
          paymentGatewayID
          afterpay
          __typename
        }
      }
    `),
    { skip: isDoctorPortal(), variables: { brand } },
  );

  const config = ((isDoctorPortal() ? doctorConfig?.config : patientConfig?.patientConfig) ??
    []) as Config[];

  return (
    <ConfigContext.Provider
      value={{
        config,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
