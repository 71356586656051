import React, { FC, useEffect } from 'react';
import { View } from 'native-base';

type Props = {
  setFormField: (id: string, value: string) => void;
};

export const FormFieldTurnstile: FC<Props> = ({ setFormField }) => {
  useEffect(() => {
    // @ts-ignore
    turnstile.render('#turnstile', {
      sitekey: '0x4AAAAAAAzBnc0JoY_pWuzi',
      theme: 'light',
      callback: (token: string) => {
        setFormField('turnstile', token);
      },
    });
  }, []);

  return <View nativeID={'turnstile'} />;
};
