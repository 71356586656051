import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { authenticationStateAtom } from '../state/authenticationState';
import * as Sentry from '@sentry/browser';
import { webAuth, doctorPortalAuth } from '../utils/auth/getAuth';
import { User } from 'react-native-auth0';
import { useRouting } from './useRouting';
import { isDoctorPortal } from '../utils/isDoctorPortal';
import { useBrand } from './useBrand';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useAuthState = () => {
  const [_, setState] = useRecoilState(authenticationStateAtom);
  const [isLoading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const { navigate } = useRouting();

  const brand = useBrand();

  const flags = useFlags();
  const hasPasswordlessAuth = flags[`${brand}-passwordless`];

  const refreshUserSession = async () => {
    setLoading(true);

    webAuth.checkSession(
      {
        ...(hasPasswordlessAuth && {
          connection: 'email',
        }),
      },
      (err, authResult) => {
        if (authResult?.accessToken) {
          webAuth.client.userInfo(authResult.accessToken, (err, user) => {
            if (user) {
              setUser(user);
              setAccessToken(authResult.accessToken);
            } else {
              setAccessToken(undefined);
            }
            setLoading(false);
          });
        } else {
          setAccessToken(undefined);
          setLoading(false);
        }
      },
    );
  };

  const refreshDoctorSession = async () => {
    setLoading(true);

    let token: string | undefined;

    try {
      token = await doctorPortalAuth.getTokenSilently();
    } catch (e) {
      console.error('>>>', e);
      // not authenticated

      setState({
        isAuthenticated: false,
        accessToken: undefined,
        user: undefined,
        isExpired: false,
        isLoading: false,
      });

      setAccessToken(undefined);
      setUser(undefined);

      navigate('/signin');

      return;
    }

    if (token) {
      try {
        const user = await doctorPortalAuth.getUser();
        setUser(user);
        setAccessToken(token);
      } catch {
        setAccessToken(undefined);
        setUser(undefined);
      }
    } else {
      setAccessToken(undefined);
    }

    setLoading(false);
  };

  const isAuth = window.document.location.pathname === '/auth-callback';

  useEffect(() => {
    if (isAuth) {
      if (isDoctorPortal()) {
        doctorPortalAuth
          .handleRedirectCallback()
          .then(() => {
            navigate('/');
          })
          .catch((e) => {
            Sentry.captureMessage(e);
            navigate('/');
          });
      } else {
        webAuth.parseHash((err, authResult) => {
          if (err) {
            Sentry.captureException(err);
          }

          if (authResult?.appState?.returnTo) {
            navigate(authResult.appState.returnTo);
          } else {
            navigate('/profile');
          }
        });
      }
    } else {
      isDoctorPortal() ? refreshDoctorSession() : refreshUserSession();
    }
  }, [isAuth]);

  useEffect(() => {
    setState({ isAuthenticated: !!accessToken, accessToken, user, isExpired: false, isLoading });
  }, [accessToken, isLoading]);
};
