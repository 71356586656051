import { Platform } from 'react-native';
import { devStateLocalStorageKey } from '../../DevSwitcher/state/devState';

export const isDoctorPortal = () => {
  if (Platform.OS !== 'web') {
    return false;
  }

  if (window.location.hostname === 'localhost') {
    const localStorageData = localStorage.getItem(devStateLocalStorageKey);

    if (localStorageData) {
      const { brand } = JSON.parse(localStorageData);

      return brand === 'portal';
    }
  }

  return window.location.hostname.indexOf('portal.leafdoctors.com.au') > -1;
};
