import React from 'react';
import { Image } from 'react-native';

type Props = {
    svg: any; // result of require('xxx.svg')
    width?: number;
    height?: number;
}

export const SVGImage = ({ svg, width, height}: Props) => {
    return <Image source={svg} style={{ width, height, resizeMode: 'contain'}} />
}