import { useCustomer } from './useCustomer';
import { useIsDev } from './useIsDev';
import { Platform } from 'react-native';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';

export const useAnalytics = () => {
  const { customer } = useCustomer();

  const isDev = useIsDev();

  const identifyUserByEmail = (email: string, additionalProps: Record<string, any>) => {
    // @ts-ignore
    if (_cio && _cio?.identify) {
      // @ts-ignore
      _cio.identify({
        created_at: dayjs().unix(),
        email,
        id: email,
        ...additionalProps,
      });
    }

    try {
      Sentry.setUser({
        username: email,
      });
    } catch (e) {
      /* empty */
    }

    try {
      if (mixpanel) {
        mixpanel.identify(email);

        mixpanel.people.set({
          $email: email,
        });
      }
    } catch (e) {}
  };

  const trackEvent = (name: string, _variables: Record<string, any> = {}) => {
    const variables = { ..._variables, platform: Platform.OS };

    if (isDev) {
      console.log('📊Tracking event', { name, variables });
    }

    if (Platform.OS === 'web') {
      try {
        mixpanel.track(name, variables);
      } catch (e) {}

      try {
        LogRocket.track(name);
      } catch (e) {}

      try {
        // @ts-ignore
        _cio.track(name, variables ?? {});
      } catch (e) {}

      try {
        // @ts-ignore
        dataLayer.push({ event: name, email: customer?.email, ...variables });
      } catch (err) {}
    }
  };

  return { trackEvent, identifyUserByEmail };
};
