import {Box, HStack, Pressable, Text} from "native-base";
import {useLocation} from "../../Common/components/Navigation"
import {useBrand} from "../../Common/hooks/useBrand";
import React, {useMemo} from "react";
import {useNavigate} from "../../Common/components/Navigation"
import {atom, useRecoilState} from "recoil";
import {useIsMobile} from "../../Common/hooks/useIsMobile";

export const expandedMenuOpenState = atom({
    key: 'expandedMenuOpen',
    default: false,
});

export function LinkProxy({to, children}) {
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const onPress = () => {

        if (to.indexOf('/') > -1) {
            navigate(to);
        } else if (pathname === '/') {
            document?.getElementById(to)?.scrollIntoView();
        } else {
            navigate(`/#${to}`)
        }
    }

    return (<Pressable onPress={onPress}>{children}</Pressable>)
}


export function Menu() {
    const brand = useBrand();

    const [expandMenuOpen, setExpandMenuOpen] = useRecoilState(expandedMenuOpenState);

    const {pathname} = useLocation();
    const {isMobile} = useIsMobile();

    const items = useMemo(() => {
        if (brand === 'heyfella') {
            return [
                {name: "PrEP Delivered", link: ""},
                {name: "How It works?", link: "how-it-works"},
                {name: "STI Testing", link: "/services"},
                {name: "Our Doctors", link: "doctors"},
                {name: "FAQs Answered", link: "faqs"},
            ];
        }

        return [];
    }, [name]);

    const itemStyledProps = {
        ...(brand === 'heyfella' && {
            textDecorationLine: true,
            textDecoration: "underline",
            textDecorationColor: 'rgb(174 205 168)'
        })
    }

    return (
        <Box alignItems="center">
            <HStack w="100%" maxW={900} alignItems={'center'}>
                {items.map((item) => (
                    <Box flex={1} h={'100%'} p={2} px={4} key={item.name} alignItems="center">
                        <LinkProxy to={item.link}>
                            <Box flex={1} justifyItems={'center'}>
                                <Text
                                    fontSize="sm"
                                    w={'100%'}
                                    fontWeight={pathname === item.link ? 'bold' : 'normal'}
                                    textAlign="center"
                                    {...itemStyledProps}
                                >{item.name}
                                </Text>
                            </Box>
                        </LinkProxy>
                    </Box>
                ))}
            </HStack>
        </Box>
    );
}
