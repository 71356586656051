import React from 'react';
import { Checkbox, HStack, Text } from 'native-base';

export const FormFieldCheckbox = ({
  value,
  id,
  setFormField,
  isError,
  options,
  label,
  loading,
  isValid,
}) => {
  return (
    <HStack>
      <Checkbox
        value={value}
        aria-label={label}
        _disabled={loading}
        onChange={(e) => setFormField(id, e)}
      >
        <Text>{label}</Text>
      </Checkbox>
    </HStack>
  );
};
