import { VStack, Image, Text, Button, Box } from 'native-base';
import React, { useEffect } from 'react';
import { useDoctorPortalLogin } from '../hooks/useDoctorPortalLogin';
import { useRecoilValue } from 'recoil';
import { authenticationStateAtom } from '../../Common/state/authenticationState';
import { useRouting } from '../../Common/hooks/useRouting';
import { useTranslation } from 'react-i18next';

const DoctorLogin = () => {
  const { login } = useDoctorPortalLogin();
  const { isAuthenticated, isLoading } = useRecoilValue(authenticationStateAtom);
  const { navigate } = useRouting();
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate('/');
    }
  }, [isAuthenticated, isLoading]);

  return (
    <Box w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'center'}>
      <Box
        p={4}
        borderRadius={'md'}
        borderWidth={1}
        borderColor="gray.200"
        justifyContent={'center'}
        alignItems={'center'}
        w={300}
        h={300}
      >
        <VStack mb={4} space={4} flex={1} justifyContent={'center'} alignItems={'center'}>
          <Text textAlign="center" fontWeight={600} fontSize="3xl">
            {t('Signin')}
          </Text>

          <Button onPress={login}>{t('Signin')}</Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default DoctorLogin;
