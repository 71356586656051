import { ComponentType, ReactNode } from "react";
import { isDev } from "./Common/hooks/useIsDev";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { isDoctorPortal } from "./Common/utils/isDoctorPortal";
import { brandForDomain } from "./Common/utils/platform/brand";

export const withLDWrapper: (children: ReactNode) => ComponentType<{}> = (children) => {
    return withLDProvider({
        clientSideID: isDev() ? '65ca0278ff7d430ff3fc74f8' : '65ca0278ff7d430ff3fc74f9',
        context: {
          kind: 'user',
          key: `user-key-${isDoctorPortal() ? 'portal' : brandForDomain()}`,
        },
        reactOptions: { useCamelCaseFlagKeys: false },
      })(() => children);
};