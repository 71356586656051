import { Alert, Box, VStack } from 'native-base';
import React, { FC, useMemo, useState } from 'react';
import { Form } from '../../Form/components/Form';
import { useIsMobile } from '../../Common/hooks/useIsMobile';

type Props = {
  onSubmitEmail: (email: string, turnstile: string) => Promise<void>;
};

export const PasswordlessLoginStep1: FC<Props> = ({ onSubmitEmail }) => {
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = useMemo(() => {
    const fields = [
      {
        id: 'email',
        name: 'Email Address',
        type: 'text',
        page: 1,
        value: '',
      },
      {
        id: 'turnstile',
        type: 'turnstile',
        page: 1,
        value: '',
      },
    ];

    return {
      fields,
      validation: JSON.stringify({
        type: 'object',
        properties: {
          email: {
            type: 'string',
            format: 'email',
          },
          turnstile: {
            type: 'string',
            minLength: 2,
          },
        },
        required: ['email', 'turnstile'],
        additionalProperties: false,
        errorMessage: {
          properties: {
            email: 'Please enter your email address',
          },
        },
      }),
    };
  }, []);

  const onFormSubmit = async (
    _: any,
    { email, turnstile }: { email: string; turnstile: string },
  ) => {
    try {
      setIsLoading(true);
      await onSubmitEmail(email, turnstile);
    } finally {
      setIsLoading(false);
    }
  };

  const { isNative } = useIsMobile();

  return (
    <>
      <Box alignItems={'center'}>
        <VStack
          space={2}
          p={4}
          mt={4}
          borderRadius="md"
          bg="white"
          {...(isNative && { w: '100%' })}
        >
          <Form
            formSchema={formSchema}
            loading={isLoading}
            onSubmit={onFormSubmit}
            labelColor={'loginLabel'}
            submitButtonTitle={'Send email to complete sign in >'}
          />
        </VStack>
      </Box>
    </>
  );
};
