import { Text, HStack, Pressable, VStack, useToken, Box } from 'native-base';
import React from 'react';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';

import { faFaceMask } from '@fortawesome/pro-duotone-svg-icons/faFaceMask';
import { faHeadSide } from '@fortawesome/pro-duotone-svg-icons/faHeadSide';
import { faHeadSideBrain } from '@fortawesome/pro-duotone-svg-icons/faHeadSideBrain';
import { faPersonRays } from '@fortawesome/pro-duotone-svg-icons/faPersonRays';
import { faStomach } from '@fortawesome/pro-duotone-svg-icons/faStomach';
import { faFemale } from '@fortawesome/pro-duotone-svg-icons/faFemale';
import { runHaptic } from '../../Common/utils/haptics';
import { FormFieldAlertConsult } from './FormFieldAlertConsult';
import { Icon } from '../../Common/components/Icon';
import { TappableAreaHeight } from '../../HeyFellaMobileApp/Common/TappableAreasConst';
import { useIsMobile } from '../../Common/hooks/useIsMobile';
import { FormFieldAlertMedicare } from './FormFieldAlertMedicare';

export const FormFieldOptions = ({
  isError,
  value,
  options,
  error,
  multi,
  loading,
  id,
  setFormField,
}) => {
  const [formOptionTextSelectedColor] = useToken('colors', ['formOptionTextSelected']);

  const showConsultAlert = id === 'before' && value === 'No';
  const showMedicareAlert = id === 'medicare' && value === 'No';

  const { isNative } = useIsMobile();

  const selectableOptions = options.map((option) => {
    const selected = (() => {
      if (!multi) return value === option;

      return value?.split('|&|')?.includes(option);
    })();

    const onPress = () => {
      runHaptic('impactLight');

      if (!multi) {
        setFormField(id, option);
        return;
      }

      let joinedOptions = value?.split('|&|') ?? [];

      if (joinedOptions.includes(option)) {
        joinedOptions = joinedOptions.filter((a) => a !== option);
      } else {
        joinedOptions = [...joinedOptions, option];
      }

      setFormField(id, joinedOptions.join('|&|'));
    };

    const styleProps = {
      ...(options.length <= 3 && {
        flex: 1,
      }),
      ...(isNative && {
        h: TappableAreaHeight,
      }),
    };

    const customIconForOptions = () => {
      if (option === 'Back pain')
        return <Icon style={{ paddingRight: 5 }} icon={faPersonRays} />;
      if (option === 'Gastro')
        return <Icon style={{ paddingRight: 5 }} icon={faStomach} />;
      if (option === 'Headache/Migraine')
        return <Icon style={{ paddingRight: 5 }} icon={faHeadSideBrain} />;
      if (option === 'Mental health')
        return <Icon style={{ paddingRight: 5 }} icon={faHeadSide} />;
      if (option === 'Common cold or flu')
        return <Icon style={{ paddingRight: 5 }} icon={faFaceMask} />;
      if (option === 'Period pain')
        return <Icon style={{ paddingRight: 5 }} icon={faFemale} />;

      return null;
    };

    return (
      <Pressable
        onPress={onPress}
        alignItems="center"
        justifyContent="center"
        p={2}
        disabled={loading}
        opacity={loading ? 0.5 : 1}
        borderRadius="xl"
        borderWidth={1}
        bg={selected ? 'formOptionBGSelected' : 'formOptionBG'}
        borderColor={selected ? 'formOptionBorderSelected' : 'formOptionBorder'}
        key={option}
        {...styleProps}
      >
        <Text
          color={selected ? 'formOptionTextSelected' : 'formOptionText'}
          fontWeight={selected ? 600 : null}
        >
          {selected && (
            <Box mr={1}>
              <Icon color={formOptionTextSelectedColor} icon={faCircleCheck} />
            </Box>
          )}
          {customIconForOptions()}
          {option}
        </Text>
      </Pressable>
    );
  });

  if (options.length <= 3) {
    return (
      <VStack space={2}>
        <HStack flex={1} space={2}>
          {selectableOptions}
        </HStack>

        {showConsultAlert && <FormFieldAlertConsult value={value} id={id} />}

        {showMedicareAlert && <FormFieldAlertMedicare />}
      </VStack>
    );
  }

  return (
    <VStack flex={1} space={2}>
      {selectableOptions}
    </VStack>
  );
};
