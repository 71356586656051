import auth0 from 'auth0-js';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { getBrandAuthConfig } from './getBrandAuthConfig';

export const redirectUri =
  window.location.hostname === 'localhost'
    ? `http://localhost:19006/auth-callback`
    : `https://${window.location.hostname}/auth-callback`;

export const webAuth = new auth0.WebAuth({
  ...getBrandAuthConfig(),
  scope: 'openid profile email offline_access',
  responseType: 'token',
  grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
  redirectUri,
} as any);

export const doctorPortalAuth = new Auth0Client({
  clientId: getBrandAuthConfig().clientID,
  domain: getBrandAuthConfig().domain,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: getBrandAuthConfig().audience,
    scope: 'openid profile email offline_access',
    redirect_uri: redirectUri,
  },
});
