import { doctorPortalAuth } from '../../Common/utils/auth/getAuth';
import { isDev } from '../../Common/hooks/useIsDev';

export const useDoctorPortalLogin = () => {
  const login = () =>
    doctorPortalAuth.loginWithRedirect({
      authorizationParams: { acr_values: isDev() ? 'devportal' : 'portal' },
    });

  return {
    login,
  };
};
