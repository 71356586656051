import { useRouting } from '../../Common/hooks/useRouting';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '../../__generated__';
import { Report } from '../../__generated__/graphql';
import { useEffect, useState } from 'react';

export const useReport = () => {
  const { params } = useRouting();

  const [reportURL, setReportURL] = useState<null | string>(null);

  const [reportURLMutation] = useMutation(
    graphql(`
      mutation reportURL($id: ID) {
        reportURL(id: $id)
      }
    `),
  );

  const { data, loading } = useQuery(
    graphql(`
      query report($id: ID) {
        report(id: $id) {
          name
          id
        }
      }
    `),
    {
      variables: {
        id: params?.ID,
      },
    },
  );

  useEffect(() => {
    if (params?.ID) {
      setReportURL(null);

      reportURLMutation({ variables: { id: params?.ID } }).then(({ data }) => {
        if (data?.reportURL) {
          setReportURL(data?.reportURL);
        }
      });
    }
  }, [params?.ID]);

  return {
    loading: loading || !reportURL,
    reportURL,
    report: data?.report as Report,
  };
};
