import React, { memo, useEffect, useRef } from 'react';
import { VStack, Text, Box, HStack, Pressable, Image, Button } from 'native-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useLocation } from '../Common/components/Navigation';
import { useBrandName } from '../Common/hooks/useBrand';
import { faStethoscope } from '@fortawesome/pro-solid-svg-icons/faStethoscope';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { TrustPilot } from '../Common/components/TrustPilot';
import { FooterLeafDoctors } from './FooterLeafDoctors';
import { Icon } from '../Common/components/Icon';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import LogRocket from 'logrocket';

function Link({ name, to, icon, opacity = 1, fontWeight = 600, fontSize = '2xs' }) {
  const navigate = useNavigate();

  return (
    <Pressable opacity={0.7} flex={1} onPress={() => navigate(to)}>
      <Text
        opacity={opacity}
        textAlign="center"
        color="footerText"
        fontSize={fontSize}
        textDecorationLine={true}
        textDecoration="underline"
      >
        {icon && <Icon icon={icon} />} {name}
      </Text>
    </Pressable>
  );
}

function Footer() {
  const location = useLocation();
  const { name, abn, entity, brand, paymentMethods, tag, email, doctorPortal } = useBrandName();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      _cio.page(location.pathname);
    } catch (e) {}

    try {
      __adroll.track('pageView');
    } catch (e) {}
  }, [location.pathname]);

  if (brand === 'leafdoctors') return <FooterLeafDoctors />;

  return (
    <Box alignItems="center">
      <VStack borderTopWidth={1} borderTopColor="footerLine" maxW={900} space={2} p={4}>
        {!doctorPortal && (
          <HStack
            w={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            mt={2}
            mb={4}
            space={2}
          >
            <Image
              borderRadius={'sm'}
              h={8}
              w={10}
              source={require('../Assets/PaymentMethods/1.png')}
            />
            <Image
              borderRadius={'sm'}
              h={8}
              w={10}
              source={require('../Assets/PaymentMethods/2.png')}
            />
            <Image
              borderRadius={'sm'}
              h={8}
              w={10}
              source={require('../Assets/PaymentMethods/22.png')}
            />
          </HStack>
        )}

        <Text textAlign="center" fontWeight={600} fontSize="xs">
          {brand === 'heyfella' && <Image source={require('../Assets/gay.png')} w={4} h={4} />}{' '}
          {name} - {tag}
        </Text>

        <Box alignItems={'center'}>
          <Pressable
            opacity={0.7}
            flex={1}
            onPress={() => navigate('/contact')}
            textAlign={'center'}
          >
            <Text textAlign="center" color="footerText" fontSize="2xs">
              {email} | 3 Prospect Street, Fortitude Valley QLD 4006{' '}
              <Image source={require('../Assets/australia.png')} w={4} h={4} />
            </Text>
          </Pressable>
        </Box>

        {!doctorPortal && (
          <Text textAlign="center" color="footerText" fontSize="2xs">
            If you are experiencing chest pain, shortness of breath, or your consultation is for a
            child who is under three months old and has a fever, then you should go straight to the
            nearest emergency department or dial 000. Use of our services are governed by our terms
            of use. {entity} ABN {abn}
          </Text>
        )}

        {!doctorPortal && (
          <HStack mb={4} space={2}>
            <Link to={'/profile'} name="My Account" />
            <Link to={'/contact'} name="Contact Us" />
            <Link to={'/services'} name="Our Services" />
            <Link to={'/privacy-policy'} name="Privacy Policy" />
            <Link to={'/terms-of-use'} name="Terms of Use" />
          </HStack>
        )}

        <Text pb={8} opacity={0.5} textAlign="center" color="footerText" fontSize="2xs">
          Version {Platform.OS === 'web' ? __VERSION__ : DeviceInfo.getVersion()}
        </Text>
      </VStack>
    </Box>
  );
}

export default memo(Footer);
