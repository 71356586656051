import React, { useEffect, useState } from 'react';
import { Box, Input, Pressable, Text, VStack } from 'native-base';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons/faXmarkCircle';
import { useDebounce } from '@uidotdev/usehooks';
import { Icon } from '../../Common/components/Icon';
import { faMapPin } from '@fortawesome/pro-solid-svg-icons/faMapPin';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { ActivityIndicator } from 'react-native';

const getAddressPart = (address_components, type) => {
  return address_components.find((comp) => comp.types.includes(type)).short_name;
};

type AutoCompleteResult = {
  canonical_address_id: string;
  full_address: string;
  id: string;
};

export const FormFieldAddress = ({
  value,
  id,
  setFormField,
  isError,
  subType = 'text',
  loading,
  isValid,
  large,
  setFocused,
  autocomplete,
}) => {
  const [val, setVal] = useState(value);

  const [searching, setSearching] = useState(false);

  const [hasFocus, setHasFocus] = useState(false);

  const [autocompleteResults, setAutoCompleteResults] = useState<AutoCompleteResult[]>([]);

  const debouncedAddress = useDebounce(val, 250);

  const lookupAddress = async (address: string) => {
    if (!hasFocus) return;

    setSearching(true);

    const result = await fetch(
      `https://api.addressfinder.io/api/au/address/autocomplete/?key=YKD6FVA4NGRQJMEHLB9T&q=${encodeURI(
        address,
      )}&format=json&source=gnaf%2Cpaf`,
    );

    const resultsJSON = await result.json();

    setSearching(false);
    setAutoCompleteResults(resultsJSON?.completions);
  };

  const selectAddress = async (address: AutoCompleteResult) => {
    setVal(address.full_address);

    setAutoCompleteResults([]);

    const result = await fetch(
      `https://api.addressfinder.io/api/au/address/metadata/?key=YKD6FVA4NGRQJMEHLB9T&format=json&id=${address.id}&source=gnaf%2Cpaf`,
    );

    const resultsJSON = await result.json();

    if (!resultsJSON) return;

    setFormField('street', resultsJSON?.address_line_combined ?? '');
    setFormField('postcode', resultsJSON?.postcode ?? '');
    setFormField('suburb', resultsJSON?.locality_name ?? '');
    setFormField('state', resultsJSON?.state_territory);
    setFormField('address', address.full_address);
  };

  useEffect(() => {
    lookupAddress(debouncedAddress).catch(null);
  }, [debouncedAddress]);

  return (
    <>
      <Input
        value={val ?? ''}
        size="lg"
        bg={'white'}
        onFocus={() => {
          setHasFocus(true);
          setFocused(true);
        }}
        onBlur={() => {
          setHasFocus(false);
          setFocused(false);
        }}
        type={'text'}
        isDisabled={loading}
        multiline={large}
        style={{
          ...(large && {
            height: 80,
          }),
          opacity: loading ? 0.5 : 1,
        }}
        InputLeftElement={
          <Box p={2}>
            <Icon icon={faSearch} size="sm" />
          </Box>
        }
        InputRightElement={
          <Box p={2}>
            {searching && <ActivityIndicator size={'small'} />}
            {isError && <Icon icon={faXmarkCircle} size="sm" color="red" />}
            {isValid && <Icon icon={faCheckCircle} size="sm" color="green" />}
          </Box>
        }
        onChangeText={(text) => setVal(text)}
      />

      {autocompleteResults?.length > 0 && (
        <VStack mt={2} bg={'gray.50'}>
          {autocompleteResults?.map((address) => (
            <Pressable
              borderBottomWidth={1}
              borderBottomColor={'gray.300'}
              key={address.full_address}
              onPress={() => selectAddress(address)}
              py={3}
              px={4}
            >
              <Text>
                <Icon icon={faMapPin} size={'sm'} /> {address?.full_address}
              </Text>
            </Pressable>
          ))}
        </VStack>
      )}
    </>
  );
};
