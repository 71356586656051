import { gql, useMutation } from '@apollo/client';
import { brandForDomain } from '../../Common/utils/platform/brand';
import { useIsMobile } from '../../Common/hooks/useIsMobile';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { usePasswordlessComplete } from './usePasswordlessComplete';
import { useAnalytics } from '../../Common/hooks/useAnalytics';

export const usePasswordless = () => {
  const brand = brandForDomain();

  const { completePasswordless } = usePasswordlessComplete();

  const { isNative } = useIsMobile();

  const { trackEvent } = useAnalytics();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>();

  const [emailSent, setEmailSent] = useState(false);

  const [startMutation] = useMutation(gql`
    mutation passswordlessSignin(
      $brand: String
      $email: String
      $turnstileToken: String
      $platform: String
    ) {
      passwordlessLogin(
        brand: $brand
        email: $email
        platform: $platform
        turnstileToken: $turnstileToken
      )
    }
  `);

  const startPasswordless = async (email: string, turnstileToken: string) => {
    setError(null);
    setLoading(true);
    setEmailSent(false);

    trackEvent('signin_start');

    const response = await startMutation({
      variables: {
        brand,
        email,
        turnstileToken,
        platform: isNative ? 'app' : 'web',
      },
    });

    setLoading(false);

    if (response.errors && response.errors?.length > 0) {
      setEmailSent(false);
      setError('A error occurred. Please try again');

      Sentry.captureException('Auth0 Passwordless - API error');

      return;
    }

    setEmailSent(true);
  };

  const finishPasswordLess = async (email: string, verificationCode: string): Promise<boolean> => {
    setLoading(true);
    setError(null);

    const result = await completePasswordless(email, verificationCode);

    setLoading(false);

    if (result) {
      trackEvent('signin_complete');
    } else {
      trackEvent('signin_failed');

      setError(
        'We could not sign you in. The wrong verification code was entered. Code are valid for 5 minutes. Please try again',
      );
    }

    return result;
  };

  const reset = () => {
    setError(null);
    setEmailSent(false);
  };

  return {
    emailSent,
    startPasswordless,
    finishPasswordLess,
    error,
    loading,
    reset,
  };
};
