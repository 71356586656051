import { brandForDomain } from '../utils/platform/brand';
import { isDoctorPortal } from '../utils/isDoctorPortal';

export const useBrand = () => {
  return brandForDomain();
};

export const useBrandName = () => {
  const brand = brandForDomain();

  if (brand === 'heyfella') {
    return {
      brand,
      entity: '91 Developments Pty Ltd trading as Hey Fella',
      abn: '37 653 856 707',
      gtm: 'GTM-W2S9C6J',
      digitalLine: '720b3d7b912bd8278ead154f4af254f1',
      phonenumber: '(07) 3497 6754',
      cio: '0414a898ca6b9320d1f4',
      name: 'Hey Fella',
      tag: 'Online healthcare for gay aussie men',
      email: 'support@heyfella.com.au',
      pusher: 'c22f0aec-6e99-45d3-820b-a19693c26592',
      auth: ['apple', 'google'] as string[],
      doctorPortal: isDoctorPortal(),
      paymentMethods: ['card'],
      paymentGateway: 'eway',
      paymentGatewayName: 'eWay',
      paymentGatewayKey:
        '6n3y5JmAFu5ZT6QGhJp1EhBIIs8gc3gZsLrhvwcrwsgvbCYBqrwI8XAwjCIkAFNgff3iQb/qFNDxhdTTIVH27/MrnDeAEDNuSEHv194EcQCqebjKPpt145sKFWJLaE4I6+fBcp92uOfiSyHnxnwqBm2kZfRD3H1LMGM8LXNxfMyQTWeDt79znIoBKbI2ox+UcxQs6jY6uKH6ZQFiHTCXYCrTq+tJ+hGVPykJ784acmJ4dNqMvQHT4HyBkp6QZQy3yJTTCMicKFJmhyx7hvJdafkuYxH+Dp+KJr2X4vdUe4OJ5kHlB2rkbGU43U0YS9mb9EiwnG5TmKJyAbNDQkunzQ==',
      paymentGatewayKeyDev:
        'zTowozIjH19oBdIJsbvmsymjtsI9CekS6iAzbPltrGXAe746x473dWZbxzvNJtCT3g5ojFPYsOT8ph3weWnto301xrpWaG5at2w7uj0KIsknduKggbvrYzHCmGQapctEF153CBeQ2yCv2+58BKx7r0h0WuzvEEx9vqsUPaAQsn9gRdP7WMSaRQC7n5d/Nty9/jTrJ6h7yQYikkSy0KfnVGFLToPEFOJpu7OaVnxltPv9AB7Tc9RNLycY63c5W/ZK0CRXz4r6lExqsgFtyJg4VAANryuhKTubbqxRifKMwNsXK9ngGIl9O/dIFJ4upegQjHWD1Rjd7mIJD41FIhJ5jQ==',
      font: 'DM+Sans:wght@400;500;700&family=DM+Serif+Display&family=Playfair+Display:wght@600;700',
      backgroundColor: '#edffe8',
      live: true,
    };
  }

  if (brand === 'leafdoctors') {
    return {
      brand,
      entity: 'The Leaf Doctors CLinic Pty Ltd',
      abn: '56 671 455 646',
      digitalLine: 'c9e787a315fd34e74b0af82ce6bab184',
      phonenumber: '07 3497 6755',
      gtm: 'GTM-598VT8ZQ',
      cio: '50edda24654518b6b24c',
      name: 'Leaf Doctors',
      tag: 'Plant-based medicine doctors',
      email: 'support@leafdoctors.com.au',
      auth: [] as string[], //magiclink
      font: 'Raleway:wght@200;400',
      backgroundColor: '#f7f5ed',
      doctorPortal: isDoctorPortal(),
      pusher: 'b6f0b947-ad67-42d2-a996-78f186fedd2e',
      paymentMethods: ['card'],
      paymentGateway: 'eway',
      paymentGatewayName: 'eWay',
      paymentGatewayKey:
        '6n3y5JmAFu5ZT6QGhJp1EhBIIs8gc3gZsLrhvwcrwsgvbCYBqrwI8XAwjCIkAFNgff3iQb/qFNDxhdTTIVH27/MrnDeAEDNuSEHv194EcQCqebjKPpt145sKFWJLaE4I6+fBcp92uOfiSyHnxnwqBm2kZfRD3H1LMGM8LXNxfMyQTWeDt79znIoBKbI2ox+UcxQs6jY6uKH6ZQFiHTCXYCrTq+tJ+hGVPykJ784acmJ4dNqMvQHT4HyBkp6QZQy3yJTTCMicKFJmhyx7hvJdafkuYxH+Dp+KJr2X4vdUe4OJ5kHlB2rkbGU43U0YS9mb9EiwnG5TmKJyAbNDQkunzQ==',
      paymentGatewayKeyDev:
        'zTowozIjH19oBdIJsbvmsymjtsI9CekS6iAzbPltrGXAe746x473dWZbxzvNJtCT3g5ojFPYsOT8ph3weWnto301xrpWaG5at2w7uj0KIsknduKggbvrYzHCmGQapctEF153CBeQ2yCv2+58BKx7r0h0WuzvEEx9vqsUPaAQsn9gRdP7WMSaRQC7n5d/Nty9/jTrJ6h7yQYikkSy0KfnVGFLToPEFOJpu7OaVnxltPv9AB7Tc9RNLycY63c5W/ZK0CRXz4r6lExqsgFtyJg4VAANryuhKTubbqxRifKMwNsXK9ngGIl9O/dIFJ4upegQjHWD1Rjd7mIJD41FIhJ5jQ==',
      live: true,
    };
  }

  return {
    brand,
    entity: '91 Developments Pty Ltd trading as Online Doctor Clinic',
    abn: '37 653 856 707',
    digitalLine: 'b182be50fe388ae83dd9bbbe359cad09',
    gtm: 'GTM-WFMQCNS',
    cio: '1363747063ec21c5072f',
    font: 'DM+Sans:wght@400;500;700&family=DM+Serif+Display&family=Playfair+Display:wght@600;700',
    backgroundColor: 'white',
    name: 'Online Doctor Clinic',
    phonenumber: '07 3497 6761',
    tag: 'making health care digital',
    email: 'support@onlinedoctor.clinic',
    auth: ['google', 'apple'],
    pusher: '1eed3c49-ebd0-45ed-ae95-7a7bce9fcd6c',
    paymentApplePayIDProd: 'merchant.au.onlinedoctor.prod',
    paymentApplePayIDDev: 'merchant.onlinedoctor.au',
    paymentMethods: ['card'],
    paymentGateway: 'eway',
    paymentGatewayName: 'eWay',
    paymentGatewayKey:
      '6n3y5JmAFu5ZT6QGhJp1EhBIIs8gc3gZsLrhvwcrwsgvbCYBqrwI8XAwjCIkAFNgff3iQb/qFNDxhdTTIVH27/MrnDeAEDNuSEHv194EcQCqebjKPpt145sKFWJLaE4I6+fBcp92uOfiSyHnxnwqBm2kZfRD3H1LMGM8LXNxfMyQTWeDt79znIoBKbI2ox+UcxQs6jY6uKH6ZQFiHTCXYCrTq+tJ+hGVPykJ784acmJ4dNqMvQHT4HyBkp6QZQy3yJTTCMicKFJmhyx7hvJdafkuYxH+Dp+KJr2X4vdUe4OJ5kHlB2rkbGU43U0YS9mb9EiwnG5TmKJyAbNDQkunzQ==',
    paymentGatewayKeyDev:
      'zTowozIjH19oBdIJsbvmsymjtsI9CekS6iAzbPltrGXAe746x473dWZbxzvNJtCT3g5ojFPYsOT8ph3weWnto301xrpWaG5at2w7uj0KIsknduKggbvrYzHCmGQapctEF153CBeQ2yCv2+58BKx7r0h0WuzvEEx9vqsUPaAQsn9gRdP7WMSaRQC7n5d/Nty9/jTrJ6h7yQYikkSy0KfnVGFLToPEFOJpu7OaVnxltPv9AB7Tc9RNLycY63c5W/ZK0CRXz4r6lExqsgFtyJg4VAANryuhKTubbqxRifKMwNsXK9ngGIl9O/dIFJ4upegQjHWD1Rjd7mIJD41FIhJ5jQ==',
    doctorPortal: isDoctorPortal(),
    live: true,
  };
};
