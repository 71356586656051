import algoliasearch from 'algoliasearch';
import { useEffect, useState } from 'react';
// @ts-ignore
import { useDebounce } from '@uidotdev/usehooks';
import { useAnalytics } from '../../Common/hooks/useAnalytics';
import aa from 'search-insights';
import { useError } from '../../Common/hooks/useError';

aa('init', {
  appId: 'GD51EVWNK2',
  apiKey: 'a768128921d6febc9aafa710d8b5f333',
});

export const searchClient = algoliasearch('GD51EVWNK2', 'a768128921d6febc9aafa710d8b5f333', {});

export type MedicationResult = {
  objectID: string;
  position: number;
  queryID: string;
  name: string;
  genericName: string;
};

const prepTerms = ['prep', 'truvada', 'tenofovir', 'emtricitabine', 'pre exposure prophylaxis'];
const doxyTerms = ['doxycycline', 'doxypep'];

const antiobioticTerms = [
  'amoxicillin + clavulanic acid',
  'amoxicillin',
  'clavulanic acid',
  'penicillamine',
  'tetracycline',
  'cefalexin',
  'roxithromycin',
  'azithromycin',
];

const weightLossTerms = ['semaglutide', 'ozempic', 'mounjaro', 'tirzepatide'];

const unsupportedDrugs = [
  'diazepam',
  'oxazepam',
  'liraglutide',
  'gabapentin',
  'tramadol + paracetamol',
  'orlistat',
  'semaglutide',
  'ozempic',
  'mounjaro',
  'tirzepatide',
  'phentermine',
  'oxycodone',
  'methylphenidate',
  'dexamfetamine',
  'modafinil',
  'lisdexamfetamine',
  'pregabalin',
  'tramadol',
  'quetiapine',
  'zolpidem',
  'temazepam',
  'cannabidiol',
  'cannabidiol + cannabichromene + cannabidivarin + cannabigerol',
  'cannabidiol + cannabigerol',
  'delta-9-tetrahydrocannabinol',
  'delta-9-tetrahydrocannabinol + cannabidiol',
  'delta-9-tetrahydrocannabinol + cannabigerol',
].sort((a, b) => a.localeCompare(b));

export const useMedicationSearch = (
  onSelectMedication: (medicationName: string | undefined | null) => void,
) => {
  const { trackEvent } = useAnalytics();

  const [searchTerm, _setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const [searching, setSearching] = useState(false);

  const [results, setResults] = useState<MedicationResult[]>([]);

  const [selectedMedication, _setSelectedMedication] = useState<MedicationResult | null>();

  const { handleError } = useError();

  const [showResults, setShowReuslts] = useState(false);

  const setSelectedMedication = (medication: MedicationResult | null) => {
    _setSelectedMedication(medication);
    onSelectMedication(medication?.name);

    if (medication) {
      try {
        aa('clickedObjectIDsAfterSearch', {
          eventName: 'clickedMedication',
          index: 'medications',
          queryID: medication.queryID,
          objectIDs: [medication.objectID],
          positions: [medication.position],
        });
      } catch (e) {
        handleError(e);
      }
    }
  };

  useEffect(() => {
    setResults([]);
  }, [searchTerm]);

  useEffect(() => {
    if (selectedMedication) {
      setResults([]);
    }
  }, [selectedMedication]);

  const doSearch = async () => {
    if (searchTerm.length < 2) {
      setSearching(false);
      setResults([]);
      return;
    }

    const results = await searchClient.search([
      {
        indexName: 'medications',
        query: searchTerm,
        params: {
          clickAnalytics: true,
          hitsPerPage: 5,
          filters: unsupportedDrugs.map((drug) => `(NOT "MP PT":"${drug}")`).join(' AND '),
        },
      },
    ]);

    // @ts-ignore
    const mppResults = results?.results[0]?.hits;
    const queryID = results?.results[0]?.queryID;

    if (mppResults?.length === 0) {
      setSearching(false);
      setResults([]);

      trackEvent('no_medication_results', { searchTerm });

      return;
    }

    const foundResults: MedicationResult[] = mppResults?.map((result: any, index: number) => {
      const medicationResult: MedicationResult = {
        genericName: result['MPP PT'],
        name: result['TPP PT'],
        objectID: result['objectID'],
        position: index + 1,
        queryID,
      };

      return medicationResult;
    });

    setSearching(false);
    setResults(foundResults);
  };

  const isTerm = (terms: string[]) => {
    const isTermInWord = (word: string | null | undefined) => {
      if (!word) return false;
      return terms.filter((a) => word?.toLowerCase().includes(a)).length > 0;
    };

    const isPrepFromResults =
      results?.filter((a) => isTermInWord(a.name) || isTermInWord(a.genericName)).length > 0;

    return (
      isTermInWord(searchTerm) ||
      isPrepFromResults ||
      isTermInWord(selectedMedication?.name) ||
      isTermInWord(selectedMedication?.genericName)
    );
  };

  const isPrep = isTerm(prepTerms);
  const isDoxy = isTerm(doxyTerms);
  const isAntibiotics = isTerm(antiobioticTerms);
  const isWeightLoss = isTerm(weightLossTerms);

  const [sentAnalytics, setSentAnalytics] = useState(false);

  useEffect(() => {
    if (isPrep && !sentAnalytics) {
      setSentAnalytics(true);

      trackEvent('searched_for_prep');
    } else if (isDoxy && !sentAnalytics) {
      setSentAnalytics(true);

      trackEvent('searched_for_doxy');
    }
  }, [isPrep, isDoxy, sentAnalytics, setSentAnalytics]);

  useEffect(() => {
    if (debouncedSearchTerm.length > 0) {
      doSearch().catch((e) => console.log(e));
    }
  }, [debouncedSearchTerm]);

  const setSearchTerm = (value: string) => {
    setSearching(value.length > 2);
    _setSearchTerm(value);
  };

  const resetSearchTerm = () => {
    setSearchTerm('');
    setSelectedMedication(null);
  };

  return {
    setSearchTerm,
    searchTerm,
    searching,
    isWeightLoss,
    resetSearchTerm,
    isDoxy,
    isAntibiotics,
    noResults:
      !selectedMedication &&
      searchTerm?.length > 1 &&
      !searching &&
      !isPrep &&
      !isWeightLoss &&
      !isAntibiotics &&
      !isDoxy &&
      results?.length === 0,
    isPrep,
    results,
    selectedMedication,
    setSelectedMedication,
    unsupportedDrugs,
  };
};
