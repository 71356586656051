import { createContext } from 'react';
import { Config } from '../../__generated__/graphql';

export type ConfigContextType = {
  config: Config[];
};

export const ConfigContext = createContext<ConfigContextType>({
  config: [],
});
