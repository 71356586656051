import passwordValidator from 'password-validator';

export const isValidPassword = (password?: string) => {
  if (!password) return false;

  const schema = new passwordValidator();

  schema.is().min(8).is().max(100).has().uppercase().has().lowercase().has().digits(1);

  const validatedPassword = schema.validate(password);

  return validatedPassword;
};
