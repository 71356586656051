import { Alert, Box, HStack, ScrollView, Text } from 'native-base';
import React, { FC, useEffect, useState } from 'react';
import { WebLayout } from '../../Layout/components/WebLayout';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import DocumentTitle from 'react-document-title';
import { useBrandName } from '../../Common/hooks/useBrand';
import { PasswordlessLoginStep1 } from './PasswordlessLoginStep1';
import { PasswordlessLoginStep2 } from './PasswordlessLoginStep2';
import { useRouting } from '../../Common/hooks/useRouting';
import Loading from '../../Common/components/Loading';
import { usePasswordless } from '../hooks/usePasswordless';
import { useIsMobile } from '../../Common/hooks/useIsMobile';
import { useRecoilValue } from 'recoil';
import { authenticationStateAtom } from '../../Common/state/authenticationState';

export const PasswordlessLogin: FC<{}> = () => {
  const { name } = useBrandName();
  const { searchParams } = useRouting();

  const { startPasswordless, loading, reset, finishPasswordLess, emailSent, error } =
    usePasswordless();

  const { navigate } = useRouting();

  const { isAuthenticated } = useRecoilValue(authenticationStateAtom);

  const [suppliedEmail, setSuppliedEmail] = useState<string>('');

  const { isNative } = useIsMobile();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/profile');
    }

    if (!isNative) {
      const magicEmail = searchParams?.get('email');
      const magicCode = searchParams?.get('code');

      if (magicEmail && magicCode) {
        finishPasswordLess(magicEmail, magicCode).then((result) => {
          if (!result) {
            searchParams.delete('email');
            searchParams.delete('code');
          }
        });
      }
    }
  }, []);

  const onSubmitEmail = async (email: string, turnstile: string) => {
    const cleanedEmail = email.toLowerCase().trim();

    setSuppliedEmail(cleanedEmail);

    await startPasswordless(cleanedEmail, turnstile);
  };

  const onSubmitCode = async (code: string) => {
    const cleanedCode = code.trim();

    await finishPasswordLess(suppliedEmail, cleanedCode);
  };

  return (
    <ScrollView height="100%">
      <DocumentTitle title={`Sign In - ${name}`} />

      <Header />

      <WebLayout p={4} maxW={400}>
        <HStack mt={4} justifyContent="space-between" alignItems="center">
          <Box flex={1} justifyContent="center" alignItems="flex-start"></Box>
          <Box flex={1} justifyContent="center" alignItems="center">
            <Text textAlign="center" fontWeight={600} fontSize="3xl">
              Sign In
            </Text>
          </Box>
          <Box flex={1}></Box>
        </HStack>

        {error && <Alert status={'error'}>{error}</Alert>}

        {loading && <Loading />}

        {!loading && !emailSent && <PasswordlessLoginStep1 onSubmitEmail={onSubmitEmail} />}

        {!loading && emailSent && (
          <PasswordlessLoginStep2 reset={reset} onSubmitCode={onSubmitCode} />
        )}
      </WebLayout>

      {!isNative && <Footer />}
    </ScrollView>
  );
};
