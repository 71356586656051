import React from 'react';
import { Checkbox, HStack, Select, Text } from 'native-base';
import { useRecoilState } from 'recoil';
import { devState } from '../state/devState';

export function DevSwitcher() {
  const [state, setState] = useRecoilState(devState);

  const onSetBrand = (brand: string) => {
    setState((existingState) => {
      return { ...existingState, brand };
    });

    window.document.location = '/';
  };

  const onSetDev = (dev: boolean) => {
    setState((existingState) => {
      return { ...existingState, dev };
    });

    window.location.reload();
  };

  const onSetLocalAPI = (localAPI: boolean) => {
    setState((existingState) => {
      return { ...existingState, localAPI };
    });

    window.location.reload();
  };

  return (
    <>
      {!state.dev && (
        <Text color={'white'} position={'absolute'} left={0} top={0} bg={'red.500'} px={8} py={2}>
          Production
        </Text>
      )}
      <HStack
        space={2}
        position={'absolute'}
        top={0}
        right={state.brand === 'portal' ? 400 : 0}
        borderRadius={'sm'}
        bg={'gray.100'}
        alignItems={'center'}
        p={1}
      >
        <Select w={40} selectedValue={state.brand} onValueChange={onSetBrand} size={'xs'}>
          <Select.Item label="Leaf Doctors" value={'leafdoctors'} />
          <Select.Item label="Doctor Portal" value={'portal'} />
        </Select>

        <Checkbox value={'dev'} isChecked={state.dev} onChange={(value) => onSetDev(value)}>
          <Text fontSize={'xs'} color={'black'}>
            Dev
          </Text>
        </Checkbox>

        <Checkbox
          value={'dev'}
          isChecked={state.localAPI}
          onChange={(value) => onSetLocalAPI(value)}
        >
          <Text fontSize={'xs'} color={'black'}>
            Local API
          </Text>
        </Checkbox>
      </HStack>
    </>
  );
}
