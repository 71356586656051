import { Platform } from 'react-native';
import { devStateLocalStorageKey } from '../../DevSwitcher/state/devState';

export const isDev = () => {
  if (Platform.OS !== 'web') {
    return __DEV__;
  }

  if (window.location.hostname === 'localhost') {
    const localStorageData = localStorage.getItem(devStateLocalStorageKey);

    if (localStorageData) {
      const { dev } = JSON.parse(localStorageData);

      return dev;
    }

    return true;
  }

  return (
    window.location.hostname === 'dev.leafdoctors.com.au' ||
    window.location.hostname === 'dev-portal.onlinedoctor.clinic' ||
    window.location.hostname === 'dev-portal-local.onlinedoctor.clinic' ||
    window.location.hostname === 'dev.heyfella.com.au' ||
    window.location.hostname === 'dev-local.heyfella.com.au' ||
    window.location.hostname === 'dev-local.leafdoctors.com.au'
  );
};

export const useIsDev = () => {
  return isDev();
};
