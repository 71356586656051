import { HStack, Text} from "native-base";
import React from "react";
import {faApple} from '@fortawesome/free-brands-svg-icons/faApple';
import {faGoogle} from '@fortawesome/free-brands-svg-icons/faGoogle';
import { Icon } from "./Icon";

export function SigninWithApple() {
    return (
        <HStack bg={'black'} space={2} p={3} alignItems={'center'} justifyContent={'center'} borderRadius={'md'}>
            <Icon icon={faApple} color={'white'} size={'xl'}  />
            <Text fontWeight={600} color={'white'}>Sign in with Apple</Text>
        </HStack>
    )
}

export function SignupWithApple() {
    return (
        <HStack bg={'black'} space={2} p={3} alignItems={'center'} justifyContent={'center'} borderRadius={'md'}>
            <Icon icon={faApple} color={'white'} size={'xl'}  />
            <Text fontWeight={600} color={'white'}>Sign up with Apple</Text>
        </HStack>
    )
}

export function SignupWithGoogle() {
    return (
        <HStack bg={'white'} borderWidth={1} borderColor={'gray.200'} space={2} p={3} alignItems={'center'} justifyContent={'center'} borderRadius={'md'}>
            <Icon icon={faGoogle} color={'black'} size={'xl'}  />
            <Text fontWeight={600} color={'black'}>Sign up with Google</Text>
        </HStack>
    )
}

export function SigninWithGoogle() {
    return (
        <HStack bg={'white'} borderWidth={1} borderColor={'gray.200'} space={2} p={3} alignItems={'center'} justifyContent={'center'} borderRadius={'md'}>
            <Icon icon={faGoogle} color={'black'} size={'xl'}  />
            <Text fontWeight={600} color={'black'}>Sign in with Google</Text>
        </HStack>
    )
}


export function PrefilledFromGoogle() {
    return (
        <HStack borderColor={'black'} borderWidth={1} bg={'white'} space={2} p={3} alignItems={'center'} justifyContent={'center'} borderRadius={'md'}>
            <Icon icon={faGoogle} color={'black'} size={'xl'}  />
            <Text fontWeight={600} color={'black'}>Prefilled from Google</Text>

        </HStack>
    )
}



export function PrefilledFromApple() {
    return (
        <HStack borderColor={'black'} borderWidth={1} bg={'white'} space={2} p={3} alignItems={'center'} justifyContent={'center'} borderRadius={'md'}>
            <Icon icon={faApple} color={'black'} size={'xl'}  />
            <Text fontWeight={600} color={'black'}>Prefilled from Apple</Text>

        </HStack>
    )
}
