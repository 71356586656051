import { Box, VStack } from 'native-base';
import React from 'react';
import Loading from '../../Common/components/Loading';
import { useBrand } from '../../Common/hooks/useBrand';
import { WebLayoutLeafDoctors } from './WebLayoutLeafDoctors';

export function WebLayout({
  borderBottomWidth,
  borderBottomColor,
  children,
  bg,
  pb,
  loading = false,
  spacing = 4,
  p = 2,
  minH,
  maxW = 900,
  py = 2,
  outsideComponent,
}) {
  const brand = useBrand();

  if (brand === 'leafdoctors')
    return (
      <WebLayoutLeafDoctors
        borderBottomWidth={borderBottomWidth}
        children={children}
        borderBottomColor={borderBottomColor}
        p={2}
        py={py}
        pb={pb}
        maxW={maxW}
        spacing={spacing}
        bg={bg}
        minH={minH}
        loading={loading}
        outsideComponent={outsideComponent}
      />
    );

  return (
    <Box alignItems="center" bg={bg} w="100%" minH={minH} overflow={'hidden'}>
      {outsideComponent}
      <Box w="100%" maxW={maxW} p={p} py={py} pb={pb}>
        <VStack
          borderBottomColor={borderBottomColor}
          borderBottomWidth={borderBottomWidth}
          space={spacing}
          flex={1}
        >
          {loading && (
            <Box alignItems={'center'}>
              <Loading />
            </Box>
          )}
          {!loading && children}
        </VStack>
      </Box>
    </Box>
  );
}
