import { devStateLocalStorageKey } from '../../../DevSwitcher/state/devState';

export const brandForDomain = () => {
  if (!window.location.hostname) return '';

  if (window.location.hostname === 'localhost') {
    const localStorageData = localStorage.getItem(devStateLocalStorageKey);

    if (localStorageData) {
      const { brand } = JSON.parse(localStorageData);

      return brand;
    }

    return 'leafdoctors';
  }

  if (window.location.hostname.indexOf('portal.leafdoctors.com.au') > -1) return 'portal';

  return 'leafdoctors';
};
