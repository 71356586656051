import React, { useRef, useState } from 'react';
import { Box, Input } from 'native-base';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons/faXmarkCircle';
import formatStringByPattern from 'format-string-by-pattern';
import { Icon } from '../../Common/components/Icon';

export function FormFieldDateRange({
  value,
  loading,
  isError,
  isValid,
  setFocused,
  id,
  setFormField,
  autocomplete,
}) {
  const ref = useRef();

  const valueFormatted = formatStringByPattern('99/99/9999', value ?? '');

  const [today, setToday] = useState();

  return (
    <Input
      value={valueFormatted}
      size="lg"
      ref={ref}
      bg={'white'}
      type="text"
      pattern="\d*"
      isDisabled={loading}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      autoComplete={autocomplete}
      InputRightElement={
        <Box p={2}>
          {isError && <Icon icon={faXmarkCircle} size="sm" color="red" />}
          {isValid && <Icon icon={faCheckCircle} size="sm" color="green" />}
        </Box>
      }
      onChangeText={(text) => setFormField(id, text)}
    />
  );
}
