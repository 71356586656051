import React, { Suspense, useEffect } from 'react';
import dayjs from 'dayjs';

import { useCustomer } from './Common/hooks/useCustomer';
import { useIsMobile } from './Common/hooks/useIsMobile';
import { useLiveChat } from './Common/hooks/useLiveChat';
import { useBrand, useBrandName } from './Common/hooks/useBrand';
import { useServices } from './Services/hooks/useServices';
import { isDoctorPortal } from './Common/utils/isDoctorPortal';
import { BannerBox } from './Common/components/Banner';
import DocumentTitle from 'react-document-title';
import { Box, StatusBar } from 'native-base';
import { Navigate, Outlet, Route, Routes, useLocation } from './Common/components/Navigation';
import { useRecoilValue } from 'recoil';
import { authenticationStateAtom } from './Common/state/authenticationState';
import Loading from './Common/components/Loading';
import { Platform } from 'react-native';
import Report from './Reports/components/Report';

const PortalHeader = React.lazy(() => import('./Header/PortalHeader'));
const PendingDispense = React.lazy(() => import('./Fufillment/views/PendingDispense'));
const RequestWrapper = React.lazy(() => import('./Request/components/RequestWrapper'));
const Feedback = React.lazy(() => import('./Feedback/components/Feedback'));

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const MedicalInformation = React.lazy(
  () => import('./MedicalInformation/components/MedicalInformation'),
);
const DoctorRequest = React.lazy(() => import('./Request/components/DoctorRequest'));
const DoctorList = React.lazy(() => import('./DoctorList/components/DoctorList'));
const Request = React.lazy(() => import('./Request/components/Request'));
const Payment = React.lazy(() => import('./Payment/components/Payment'));
const Start = React.lazy(() => import('./Start/Start'));
const Info = React.lazy(() => import('./Info/components/Info'));
const Login = React.lazy(() => import('./Login/components/Login'));
const DoctorLogin = React.lazy(() => import('./Login/components/DoctorLogin'));
const Services = React.lazy(() => import('./Services/components/Services'));
const PrivacyPolicy = React.lazy(() => import('./Legal/PrivacyPolicy'));
const TermsOfUse = React.lazy(() => import('./Legal/TermsOfUse'));
const AppointmentBookingFlow = React.lazy(
  () => import('./AppointmentBooking/components/FlowBooking'),
);
const NotFound = React.lazy(() => import('./NotFound/components/NotFound'));
const PageForm = React.lazy(() => import('./Form/components/PageForm'));
const PageBooking = React.lazy(() => import('./AppointmentBooking/components/PageBooking'));
const ProfileHeyFella = React.lazy(() => import('./Profile/views/ProfileHeyFella'));
const ProfileOverview = React.lazy(() => import('./Profile/views/Overview'));
const ProfileMessages = React.lazy(() => import('./Profile/views/Messages'));
const ProfileRequests = React.lazy(() => import('./Profile/views/Requests'));
const ProfileRefer = React.lazy(() => import('./Profile/views/Refer'));

const LeafDoctorsSignup = React.lazy(() => import('./LeafDoctors/Signup/Signup'));

const ProfileHealth = React.lazy(() => import('./Profile/views/Health'));
const ProfilePathologyResult = React.lazy(
  () => import('./HeyFellaMobileApp/Requests/views/PathologyRequest'),
);

const ProfileScript = React.lazy(() => import('./HeyFellaMobileApp/Requests/views/Script'));

const ProfileTrueGreen = React.lazy(() => import('./Profile/components/LeafDoctors/Profile'));
const UpdateProfile = React.lazy(() => import('./Profile/components/ProfileDetails'));
const DoctorAppointments = React.lazy(
  () => import('./DoctorAppointments/components/DoctorAppointments'),
);
const ProfileTrueGreenTreatmentPlan = React.lazy(
  () => import('./Profile/components/LeafDoctors/Home'),
);
const ProfileTrueGreenMessaging = React.lazy(
  () => import('./Profile/components/LeafDoctors/Messaging'),
);
const ProfileTrueGreenOrders = React.lazy(() => import('./Profile/components/LeafDoctors/Orders'));
const ProfileTrueGreenOrderRedirect = React.lazy(
  () => import('./Profile/components/LeafDoctors/OrderRedirect'),
);

const ProfileTrueGreenOrder = React.lazy(() => import('./Profile/components/LeafDoctors/Order'));
const ProfileTrueGreenAppointments = React.lazy(
  () => import('./Profile/components/LeafDoctors/Appointments'),
);
const CustomerList = React.lazy(() => import('./CustomerList/components/CustomerList'));
const DoctorHome = React.lazy(() => import('./DoctorHome/components/DoctorHome'));
const DoctorCustomer = React.lazy(() => import('./DoctorCustomer/components/DoctorCustomer'));

const DoctorUnreviewedResults = React.lazy(() => import('./DoctorPathology/views/Results'));
const DoctorPathologyResult = React.lazy(() => import('./DoctorPathology/views/Result'));
const DoctorScheduling = React.lazy(() => import('./DoctorScheduling/views/DoctorScheduling'));
const DoctorPathologyRequests = React.lazy(() => import('./DoctorPathology/views/Requests'));
const DoctorPathologyRequest = React.lazy(() => import('./DoctorPathology/views/Request'));

const Fufillment = React.lazy(() => import('./Fufillment/views/Fufillment'));
const FufillmentCompleted = React.lazy(() => import('./Fufillment/views/Completed'));
const FufillmentNewOrders = React.lazy(() => import('./Fufillment/views/NewOrders'));

const MedicationsManage = React.lazy(() => import('./DoctorMedications/views/MedicationsManage'));

const DoctorTasks = React.lazy(() => import('./DoctorTasks/components/Tasks'));

const DoctorMessagesInbox = React.lazy(() => import('./Messages/views/Inbox'));
const DoctorMessagesInboxMessages = React.lazy(() => import('./Messages/views/InboxMessages'));

const Reports = React.lazy(() => import('./Reports/components/Reports'));
const ReportsReport = React.lazy(() => import('./Reports/components/Report'));

function AuthProtectedRoute({ children }) {
  const { signedin, loading } = useCustomer();

  const { pathname } = useLocation();

  if (loading) return <Loading />;

  if (!signedin) {
    return <Navigate to={`/signin?redirect=${pathname}`} replace />;
  }

  return children || <Outlet />;
}

const doctorPortal = isDoctorPortal();

function WebApp() {
  const { isAuthenticated } = useRecoilValue(authenticationStateAtom);
  const { isMobile } = useIsMobile();

  useLiveChat();
  useServices();

  const { name, backgroundColor } = useBrandName();

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.body.style.background = backgroundColor;
    }
  }, []);

  const brand = useBrand();

  return (
    <Box backgroundColor={backgroundColor} height={'100%'}>
      <StatusBar backgroundColor={backgroundColor} barStyle="dark-content" />

      {doctorPortal && <PortalHeader />}

      <Suspense fallback={<Loading />}>
        {!doctorPortal && <BannerBox />}

        <DocumentTitle title={name} />

        <Routes>
          {!doctorPortal && brand === 'heyfella' && (
            <>
              <Route
                element={
                  <AuthProtectedRoute>
                    <ProfileHeyFella />
                  </AuthProtectedRoute>
                }
                path="/profile"
              >
                <Route element={<ProfileHealth />} path={'health'} />

                <Route element={<UpdateProfile wrap={false} />} path="info" />

                <Route element={<ProfileMessages />} path={'messages'} />

                <Route element={<ProfileRequests />} path={'requests'} />

                <Route element={<ProfileRefer />} path={'refer'} />

                <Route element={<ProfilePathologyResult />} path={'health/pathology/:requestID'} />

                <Route element={<ProfileScript />} path={'health/script/:scriptID'} />

                <Route element={<ProfileOverview />} path={''} />
              </Route>
            </>
          )}

          {!doctorPortal && brand === 'leafdoctors' && (
            <>
              <Route element={<LeafDoctorsSignup />} path={'/signup'} />

              <Route
                element={
                  <AuthProtectedRoute>
                    <ProfileTrueGreen />
                  </AuthProtectedRoute>
                }
                path="/profile"
              >
                <Route element={<ProfileTrueGreenTreatmentPlan />} path={''} />
                <Route element={<ProfileTrueGreenMessaging />} path={'messages'} />
                <Route element={<ProfileTrueGreenOrderRedirect />} path={'order-redirect'} />
                <Route element={<ProfileTrueGreenOrder />} path={'order'} />
                <Route element={<ProfileTrueGreenOrders />} path={'orders'} />
                <Route element={<ProfileTrueGreenAppointments />} path={'appointments'} />
              </Route>
            </>
          )}

          <Route element={isDoctorPortal() ? <DoctorLogin /> : <Login />} path="/signin" />

          {!doctorPortal && (
            <>
              <Route element={<Services />} path="/services" />

              <Route element={<TermsOfUse />} path="/terms-of-use" />

              <Route element={<Feedback />} path="/feedback" />

              <Route element={<PrivacyPolicy />} path="/privacy-policy" />

              <Route element={<Start />} path="/start" />

              <Route element={<Info />} path="/start/info" />

              <Route element={<Info />} path="/start/info" />

              <Route element={<AppointmentBookingFlow />} path="/start/schedule" />

              <Route element={<MedicalInformation />} path="/start/medical" />

              <Route element={<Payment />} path="/start/payment" />

              <Route
                element={
                  <AuthProtectedRoute>
                    <RequestWrapper>
                      <Request />
                    </RequestWrapper>
                  </AuthProtectedRoute>
                }
                path="/request/:requestID"
              />

              <Route
                element={
                  <AuthProtectedRoute>
                    <PageBooking />
                  </AuthProtectedRoute>
                }
                path="/book/:requestID"
              />

              <Route
                element={
                  <AuthProtectedRoute>
                    <PageForm />
                  </AuthProtectedRoute>
                }
                path="/form/:formID/:requestID"
              />

              {brand !== 'heyfella' && (
                <Route
                  element={
                    <AuthProtectedRoute>
                      <UpdateProfile />
                    </AuthProtectedRoute>
                  }
                  path="/profile/update"
                />
              )}
            </>
          )}

          {!isAuthenticated && doctorPortal && (
            <>
              <Route path="*" element={<Navigate replace to="/signin?redirect=/" />} />
            </>
          )}

          {isAuthenticated && doctorPortal && (
            <>
              <Route element={<DoctorHome />} path="/" />

              <Route element={<DoctorScheduling />} path="/scheduling" />

              <Route element={<DoctorMessagesInbox />} path="/inbox">
                <Route element={<DoctorMessagesInboxMessages />} path="/inbox/:ID" />
              </Route>

              <Route element={<Reports />} path="/reports">
                <Route element={<Report />} path="/reports/:ID" />
              </Route>

              <Route element={<DoctorAppointments />} path="/appointments/:type/:date" />

              <Route element={<Fufillment />} path="/fulfill">
                <Route element={<FufillmentNewOrders />} path="/fulfill/new-orders" />
                <Route element={<PendingDispense />} path="/fulfill/pending-dispense" />

                <Route element={<FufillmentCompleted />} path="/fulfill/completed" />
              </Route>

              <Route element={<MedicationsManage />} path="/medications" />

              <Route element={<DoctorTasks />} path="/tasks" />

              <Route element={<CustomerList />} path="/customers" />

              <Route element={<DoctorCustomer />} path="/customers/:customerID" />

              {!isMobile && (
                <Route element={<DoctorUnreviewedResults />} path="/pathology/results">
                  <Route element={<DoctorPathologyResult />} path="/pathology/results/:resultID" />
                </Route>
              )}

              {!isMobile && (
                <Route element={<DoctorPathologyRequests />} path="/pathology/requests">
                  <Route
                    element={<DoctorPathologyRequest />}
                    path="/pathology/requests/:requestID"
                  />
                </Route>
              )}

              {isMobile && (
                <>
                  <Route element={<DoctorUnreviewedResults />} path="/pathology/results" />
                  <Route element={<DoctorPathologyResult />} path="/pathology/results/:resultID" />
                </>
              )}

              <Route element={<DoctorRequest />} path="/request/:brand/:requestID" />

              {!isMobile && (
                <Route element={<DoctorList />} path="/requests/:brand">
                  <Route element={<DoctorRequest />} path="/requests/:brand/:requestID" />
                </Route>
              )}

              {isMobile && (
                <>
                  <Route element={<DoctorList />} path="/requests/:brand" />

                  <Route element={<DoctorRequest />} path="/requests/:brand/:requestID" />
                </>
              )}
            </>
          )}

          <Route path="/404" element={<NotFound />} />

          {brand === 'leafdoctors' || brand === 'heyfella' ? (
            brand === 'leafdoctors' && isMobile ? (
              <Route path="*" element={<Navigate replace to="/profile" />} />
            ) : (
              <Route path="*" element={<Navigate replace to="/services" />} />
            )
          ) : (
            <Route path="*" element={<Navigate replace to="/404" />} />
          )}
        </Routes>
      </Suspense>
    </Box>
  );
}

export default WebApp;
