import React from 'react';

export function Webview({ url, srcDoc }: { url?: string; srcDoc?: string }) {
  return (
    <iframe
      srcDoc={srcDoc}
      style={{
        border: 0,
        width: '100%',
        height: 1100,
        overflow: 'hidden',
      }}
      src={url}
    />
  );
}
