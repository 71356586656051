import React from 'react';
import { Box, Text } from 'native-base';
import { FormFieldTextMedicationSearch } from './FormFieldTextMedicationSearch';

export type FormFieldTextMedicationProps = {
  id: string;
  value: string;
  loading: boolean;
  setFormField: (id: string, value: string | null | undefined) => void;
};

export const FormFieldTextMedication = ({
  id,
  value,
  setFormField,
  loading,
}: FormFieldTextMedicationProps) => {
  return (
    <Box>
      <Text mb={2}>
        Type in the name from the label of the medication that you have been prescribed before
      </Text>

      <FormFieldTextMedicationSearch
        loading={loading}
        value={value}
        onSelectMedication={(medicationName) => setFormField(id, medicationName)}
      />
    </Box>
  );
};
