import { useNavigate, useParams, useLocation, useSearchParams } from '../components/Navigation';

export const useRouting = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  return {
    navigate,
    params,
    pathname,
    setSearchParams,
    searchParams,
  };
};
