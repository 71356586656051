import { useBrandName } from './useBrand';
import { useTimezones } from './useTimezones';

export const useCallNow = () => {
  const { brisbaneTime } = useTimezones();

  const { phonenumber } = useBrandName();

  const isOpen = () => {
    const hour = brisbaneTime().hour();

    return hour >= 6 && hour <= 23;
  };

  const callNow = () => {
    window.open(`tel:${phonenumber}`);
  };

  const chatNow = () => {
    try {
      zE('messenger', 'open');
    } catch (e) {}
    try {
      Intercom('show');
    } catch (e) {}
  };

  return {
    chatNow,
    callNow,
    isOpen,
  };
};
