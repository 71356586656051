import React from 'react';
import { Box, Button, HStack, Pressable, Text, useToken } from 'native-base';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { useLocation, useNavigate } from '../Common/components/Navigation';
import { Menu } from '../Menu/components/Menu';
import { useBrand, useBrandName } from '../Common/hooks/useBrand';
import LogoHeyFella from './LogoHeyFella';
import { faCommentQuestion } from '@fortawesome/pro-solid-svg-icons/faCommentQuestion';
import { useRecoilValue } from 'recoil';
import { useCallNow } from '../Common/hooks/useCallNow';
import { HeaderLeafDoctors } from './HeaderLeafDoctors';
import { Icon } from '../Common/components/Icon';
import { SafeAreaView } from 'react-native';
import { authenticationStateAtom } from '../Common/state/authenticationState';

function Header({
  backButton = false,
  backButtonTarget,
  hideRight = false,
  onLogoPress,
  logo,
  hideMenu = false,
}) {
  const navigate = useNavigate();

  const { isAuthenticated, isLoading } = useRecoilValue(authenticationStateAtom);

  const { pathname } = useLocation();

  const brand = useBrand();

  const { doctorPortal } = useBrandName();

  const canShowMenu =
    !doctorPortal &&
    brand !== 'heyfella' &&
    !pathname.startsWith('/doctor') &&
    !pathname.startsWith('/form') &&
    !pathname.startsWith('/start') &&
    !pathname.startsWith('/book');

  const [openNowColor, headerBackArrow, headerAccountColor] = useToken('colors', [
    'openNow',
    'headerBackArrow',
    'headerAccount',
  ]);

  const styledProps = {
    ...(brand === 'heyfella' && {
      bg: 'white',
    }),
  };

  const { chatNow } = useCallNow();

  const onStartPress = () => {
    navigate('/services');
  };

  if (brand === 'leafdoctors') return <HeaderLeafDoctors />;

  return (
    <>
      <Box {...styledProps}>
        <SafeAreaView style={{ alignItems: 'center' }}>
          <Box
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
            h={16}
            w="100%"
            maxW={900}
          >
            <Box w={20} pl={2} justifyItems="center" justifyContent="center" alignItems="center">
              {backButton && (
                <Pressable
                  onPress={() => {
                    if (backButtonTarget) {
                      navigate(backButtonTarget);
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <Icon icon={faChevronLeft} color={headerBackArrow} size="lg" />
                </Pressable>
              )}

              {!backButton && !(brand === 'heyfella' && pathname.startsWith('/profile')) && (
                <Pressable onPress={() => navigate('/services')}>
                  <HStack
                    justifyItems="center"
                    justifyContent="center"
                    alignItems="center"
                    space={1}
                  >
                    {brand === 'heyfella' && (
                      <Button
                        onPress={() => navigate('/profile')}
                        size={'sm'}
                        variant={'secondary'}
                      >
                        {isAuthenticated ? 'Profile' : 'Sign In'}
                      </Button>
                    )}
                  </HStack>
                </Pressable>
              )}
            </Box>

            <Pressable
              flex={1}
              onPress={() => {
                if (brand === 'heyfella') {
                  navigate('/services');
                } else if (onLogoPress) {
                  onLogoPress();
                } else {
                  navigate('/');
                }
              }}
            >
              {!logo && brand === 'heyfella' && <LogoHeyFella />}

              {logo}
            </Pressable>

            <Box alignItems="flex-end" pr={2}>
              {!hideRight && brand !== 'heyfella' && !isLoading && (
                <Button size={'sm'} onPress={onStartPress}>
                  Start {'>'}
                </Button>
              )}

              {(hideRight || brand === 'heyfella') && (
                <HStack alignItems={'center'}>
                  <Button
                    _text={{ color: 'headerHelp' }}
                    size={'sm'}
                    variant="outline"
                    onPress={chatNow}
                  >
                    <HStack space={1} alignItems={'center'}>
                      <Icon size={'sm'} icon={faCommentQuestion} />
                      <Text fontWeight={600} fontSize={'xs'}>
                        Live Chat
                      </Text>
                    </HStack>
                  </Button>
                </HStack>
              )}
            </Box>
          </Box>
        </SafeAreaView>
      </Box>

      {canShowMenu && !hideMenu && <Menu />}
    </>
  );
}

export default Header;
