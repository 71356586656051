import { useRecoilState } from 'recoil';
import { doctorPortalAuth, webAuth } from '../utils/auth/getAuth';
import { useError } from './useError';
import { authenticationStateAtom } from '../state/authenticationState';
import { useCallback } from 'react';
import { isDoctorPortal } from '../utils/isDoctorPortal';

export const useLogOut = () => {
  const { handleError } = useError();

  const [_, setState] = useRecoilState(authenticationStateAtom);

  const logOut = useCallback(() => {
    async function logout() {
      if (isDoctorPortal()) {
        await doctorPortalAuth.logout({
          logoutParams: { returnTo: document.location.origin, federated: true },
        });
      } else {
        webAuth.logout({
          returnTo: document.location.origin,
        });
      }
    }

    logout().catch(null);
  }, [handleError, setState]);

  return {
    logOut,
  };
};
