import { atom } from "recoil";

export const authenticationStateAtom = atom<{
    isAuthenticated: boolean;
    accessToken: string | undefined;
    user: User | undefined;
    isExpired: boolean;
    isLoading: boolean;
  }>({
    key: 'authentication',
    default: { isAuthenticated: false, accessToken: undefined, user: undefined, isExpired: false, isLoading: true },
  });

  export type User = {
    name?: string;
    givenName?: string;
    familyName?: string;
    middleName?: string;
    nickname?: string;
    preferredUsername?: string;
    profile?: string;
    picture?: string;
    website?: string;
    email?: string;
    emailVerified?: boolean;
    gender?: string;
    birthdate?: string;
    zoneinfo?: string;
    locale?: string;
    phoneNumber?: string;
    phoneNumberVerified?: boolean;
    address?: string;
    updatedAt?: string;
    sub?: string;
    [key: string]: any;
  };