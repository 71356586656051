import { useQuery } from '@apollo/client';
import { graphql } from '../../__generated__';
import { useEffect } from 'react';
import { pusherSubscribeObject } from '../../Common/utils/pusher';
import { useCustomer } from '../../Common/hooks/useCustomer';

export const useMessagesCustomerUnread = () => {
  const { customer } = useCustomer();

  const { data, loading, refetch } = useQuery(
    graphql(`
      query getMyMessages {
        getMessages {
          __typename
          id
          unreadCustomer
        }
      }
    `),
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    let unsubscribe;

    if (customer?.id) {
      const subscribeObj = pusherSubscribeObject(customer?.id, 'message', () => refetch());

      unsubscribe = subscribeObj.unsubscribe;
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [customer?.id]);

  return {
    unread: data?.getMessages?.unreadCustomer ?? false,
    loading,
  };
};
