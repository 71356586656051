import React, { FC } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { Box, Button, Image, VStack } from "native-base";
import { useRouting } from "../Common/hooks/useRouting";
import { SVGImage } from "../Common/components/SvgImage";
import { useBrand } from "../Common/hooks/useBrand";

const LeafDoctorLogo = require('../Assets/LeafDoctors/LD_Logo_Big.svg');

export const MobileOnboarding: FC = () => {
    const { navigate } = useRouting();
    const brand = useBrand();

    const Logo = brand === 'leafdoctors' ? <SVGImage
        svg={LeafDoctorLogo}
        width={285}
        height={75}
    /> : <Image source={require('../Assets/heyfellalogo.png')} width={285} height={75} />

    return (
        <SafeAreaView>
            <VStack justifyContent={"center"} alignItems={"center"} height={'100%'}>
                {Logo}
                <Box padding="10" width={"100%"}>
                    <Button onPress={() => navigate('/signin')}>Sign in</Button>
                </Box>
            </VStack>
        </SafeAreaView>
    )
}