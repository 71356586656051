import React from 'react';
import { Button, Text, VStack } from 'native-base';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { useQuickStartService } from '../../Common/hooks/useQuickStartService';
import { Icon } from '../../Common/components/Icon';

export const FormFieldAlertConsult = () => {
  const { startService, pricingForService } = useQuickStartService();
  const changeToTelehealth = () => startService('consultation');

  return (
    <VStack space={2} bg={'yellow.200'} p={4} borderRadius={'md'}>
      <Text fontWeight={600} fontSize={'md'}>
        <Icon size={'md'} icon={faExclamationCircle} /> Doctor consultation required
      </Text>

      <Text>
        Sorry, as you have not had the medication before to ensure we prescribe safely you will need
        to have a phone consultation with one of our doctors first.
      </Text>

      <Text>
        During the consultation your doctor will discuss the medication, risks and indications as
        suited for you. The cost is {pricingForService('consultation')}
      </Text>

      <Button onPress={changeToTelehealth}>Request Doctors Consultation &gt;</Button>
    </VStack>
  );
};
