import { HStack, Image } from 'native-base';
import React from 'react';

export default function () {
  return (
    <HStack space={2} flex={1} alignItems="center" justifyContent="center" flexDirection="row">
      <Image
        w={40}
        h={20}
        resizeMode="contain"
        source={{
          uri: 'https://imagedelivery.net/xVK12LCEYfLcBVg3-f6Ibg/f1210fb6-0df6-40aa-3e97-63a731d3c900/public',
        }}
      />
    </HStack>
  );
}
