import React from 'react';
import { Box, Checkbox, HStack, Image, Pressable, Text, VStack } from 'native-base';
import { useQuery } from '@apollo/client';
import { graphql } from '../../__generated__';
import { useBrand } from '../../Common/hooks/useBrand';
import { generateCloudflareImage } from '../../DoctorMedications/const/cloudflareImage';
import { useTimezones } from '../../Common/hooks/useTimezones';
import Loading from '../../Common/components/Loading';
import { runHaptic } from '../../Common/utils/haptics';

export type FormFieldPriorMedications = {
  id: string;
  value: string;
  loading: boolean;
  setFormField: (id: string, value: string | null | undefined) => void;
};

export const FormFieldPriorMedications = ({
  id,
  value,
  setFormField,
  loading,
}: FormFieldPriorMedications) => {
  const brand = useBrand();

  const isSelected = (option: string) => {
    return value?.split('|&|')?.includes(option);
  };

  const onPress = (option: string) => {
    let joinedOptions = value?.split('|&|') ?? [];

    if (joinedOptions.includes(option)) {
      joinedOptions = joinedOptions.filter((a) => a !== option);
    } else {
      joinedOptions = [...joinedOptions, option];
    }

    setFormField(id, joinedOptions.join('|&|'));
  };

  const showImage = brand === 'leafdoctors';

  const { data, loading: loadingData } = useQuery(
    graphql(`
      query priorMedications {
        previousMedications {
          medication {
            name
            id
            image
          }
          last
        }
      }
    `),
  );

  const { formatDateToUser } = useTimezones();

  return (
    <Box>
      <Text mb={2}>
        Select a prior prescription you have been issued. If you do not see your previous
        prescription please message your doctor via the messaging tab.
      </Text>

      {loadingData && <Loading />}

      <VStack space={2}>
        {[...(data?.previousMedications ?? [])]
          ?.sort((a, b) => a?.medication?.name?.localeCompare(b?.medication?.name))
          .map((product) => (
            <Pressable
              onPress={() => onPress(product?.medication?.name ?? '')}
              borderRadius={'md'}
              bg={'white'}
              key={product?.medication?.id}
            >
              <HStack space={2} p={2} alignItems={'center'}>
                <Box px={2}>
                  <Checkbox
                    value={''}
                    isChecked={isSelected(product?.medication?.name ?? '')}
                    isDisabled={loading}
                  />
                </Box>

                {showImage && (
                  <Image
                    source={{ uri: generateCloudflareImage(product?.medication?.image) }}
                    w={12}
                    h={12}
                    resizeMode={'contain'}
                  />
                )}

                <VStack flex={1}>
                  <Text fontSize={'sm'} fontWeight={600}>
                    {product?.medication?.name}
                  </Text>

                  <Text fontSize={'sm'}>Last prescribed {formatDateToUser(product?.last)}</Text>
                </VStack>
              </HStack>
            </Pressable>
          ))}
      </VStack>
    </Box>
  );
};
