import { Platform } from "react-native";
import { useBrandName } from "../hooks/useBrand";
import { brandForDomain } from "../utils/platform/brand";

export const initCss = () => {
    if (Platform.OS !== 'web') {
        return;
    }

    if (brandForDomain() === 'heyfella') {
        const head = document.getElementsByTagName('head')[0];
      
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.media = 'print';
        link.onload = (e) => (link.media = 'all');
        link.href = `https://fonts.googleapis.com/css2?family=${useBrandName().font}&display=swap`;
        head.appendChild(link);
      } else if (brandForDomain() === 'leafdoctors') {
      
        const head = document.getElementsByTagName('head')[0];
      
        var style = document.createElement('style');
        style.innerHTML = `
      @font-face {
          font-family: 'Bastardo Body Copy';
          src: url('https://uploads-ssl.webflow.com/657284098041221366af2a16/6583aa8422edf19691fa5863_Bastardo_Light_Body%20Copy.ttf') format('truetype');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Dinamit Headings';
          src: url('https://uploads-ssl.webflow.com/657284098041221366af2a16/6583aa83fc1cfcb0e1780832_Dinamit_Regular_Headings.otf') format('opentype');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Tiemposfine Subheadings';
          src: url('https://uploads-ssl.webflow.com/657284098041221366af2a16/6583aa830693f08aab156264_TiemposFine_LightItalic_Subheadings.otf') format('opentype');
          font-weight: 300;
          font-style: italic;
          font-display: swap;
      }
      `;
        head.appendChild(style);
      } else {
        const head = document.getElementsByTagName('head')[0];
      
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.media = 'print';
        link.onload = (e) => (link.media = 'all');
        link.href = `https://fonts.googleapis.com/css2?family=${useBrandName().font}&display=swap`;
        head.appendChild(link);
      }
}