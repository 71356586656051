import mixpanel from 'mixpanel-browser';
import { growthbook } from './growthbook';
import { brandForDomain } from '../utils/platform/brand';

mixpanel.init(
  brandForDomain() === 'leafdoctors'
    ? '76371e930c5425926f52bae99c8bba6b'
    : 'e87fd3492d0e3a9791eea4cfb3b0a9e4',
  {
    loaded: function (a) {
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        id: a.get_distinct_id(),
      });
    },
  },
);
