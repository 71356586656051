import React from 'react';
import { Box, Text, VStack } from 'native-base';

export function FormFieldAlertMedicare() {
  return (
    <VStack space={1} borderRadius={'sm'} bg={'yellow.200'} p={2}>
      <Text fontSize={'md'} fontWeight={600}>
        Accessing Hey Fella Without Medicare
      </Text>

      <Text>
        You can still use Hey Fella without Medicare; however, there are some important things to
        know:
      </Text>

      <Text fontWeight={600}>Pathology Testing</Text>

      <Text>
        The cost of pathology testing is not covered by Medicare (also known as bulk bill).{' '}
        <Text underline={true}>Typically, your insurance will cover the cost.</Text> If not, we have
        partnered with a pathology company that can provide the required testing for $150~
      </Text>

      <Text fontWeight={600}>eScripts & IHI</Text>

      <Text>
        As an online service, we provide scripts digitally. In order to create a digital script, we
        will require your IHI. If you do not have an IHI, you can apply for free online through
        MyGov. You do not require your IHI to get started; however, you will need it when your
        doctor provides scripts.
      </Text>
    </VStack>
  );
}
