import { useEffect, useMemo, useState } from 'react';
import Ajv from 'ajv';
import { log } from '../../Common/Logging';
import dayjs from 'dayjs';
import { useAnalytics } from '../../Common/hooks/useAnalytics';
import { formula, init } from 'expressionparser';
import { isValidPassword } from '../../Common/utils/password';

export const useForm = (formSchema, onSubmit, loading, onSetFormField) => {
  const [form, setForm] = useState();

  const { trackEvent } = useAnalytics();

  const shouldShowField = (field) => {
    if (field?.expression) {
      try {
        const parser = init(formula, (term) => {
          const transformedValue = form?.values[term] === 'Yes' ? true : false;

          return transformedValue;
        });

        return parser.expressionToValue(field?.expression);
      } catch (e) {
        console.error(e);

        return false;
      }
    }

    return true;
  };

  const fieldsShown = useMemo(() => {
    return form?.form?.fields?.filter((field) => shouldShowField(field)) ?? [];
  }, [form?.values]);

  const fieldsRequired = useMemo(() => {
    const visible = form?.form?.fields?.filter((field) => shouldShowField(field)) ?? [];

    return visible;
  }, [form?.values]);

  const setupForm = (clearValues = false) => {
    setForm({
      form: formSchema,
      submitCount: 0,
      errors: Object.fromEntries(formSchema.fields.map((field) => [field.id, null])),
      notes: Object.fromEntries(
        formSchema.fields.map((field) => {
          return [field.id, null];
        }),
      ),
      values: Object.fromEntries(
        formSchema.fields.map((field) => {
          const value = clearValues ? null : field.value ?? null;
          return [field.id, value];
        }),
      ),
      page: 1,
      valid: false,
    });
  };

  useEffect(() => {
    if (!formSchema) return;

    setupForm();
  }, [formSchema]);

  const validationSchema = useMemo(() => {
    if (!formSchema) return;

    const addFormats = require('ajv-formats');

    const ajv = new Ajv({ allErrors: true });
    require('ajv-errors')(ajv);
    addFormats(ajv);

    ajv.addFormat('mobilenumber', /^04[0-9]{8}$/);

    ajv.addFormat('password', (data) => {
      return isValidPassword(data);
    });

    ajv.addFormat('dateau', (data) => {
      const parsedDate = dayjs(data, 'DD/MM/YYYY', true);
      const isValid = parsedDate.isValid();

      if (isValid) {
        const age = dayjs().diff(parsedDate, 'year');
        if (age < 18) return false;
      }

      return isValid;
    });

    ajv.addFormat('medicarecard', (data) => {
      try {
        return data?.length > 0;
      } catch (e) {
        return true;
      }
    });

    ajv.addFormat('medicareexpoptional', (data) => {
      if (!data || data?.length < 0) return true;

      const regex = /[0-9]{2}\/[0-9]{4}?$/;

      if (!regex.test(data)) return false;

      const parsedDate = dayjs('01/' + data, 'DD/MM/YYYY', true).endOf('month');

      return dayjs().isBefore(parsedDate, 'date');
    });

    ajv.addFormat('medicareexp', (data) => {
      const regex = /[0-9]{2}\/[0-9]{4}?$/;

      if (!regex.test(data)) return false;

      const parsedDate = dayjs('01/' + data, 'DD/MM/YYYY', true).endOf('month');

      return dayjs().isBefore(parsedDate, 'date');
    });

    ajv.addFormat('medicareref', (data) => {
      const regex = /^[1-9]{1,2}$/;

      return regex.test(data);
    });

    const validationSchema = {
      ...JSON.parse(formSchema.validation),
    };

    const visibleFields = fieldsShown.map((field) => field.id);

    // If the field is required but not actually visible - removed it
    validationSchema.required = validationSchema.required.filter((a) => {
      if (!visibleFields.includes(a)) return false;

      return true;
    });

    const removeAttributes = Object.keys(validationSchema.properties).filter(
      (a) => !validationSchema.required.includes(a),
    );

    removeAttributes.forEach((field) => {
      delete validationSchema.properties[field];
    });

    return ajv.compile(validationSchema);
  }, [formSchema, fieldsShown?.length]);

  const processError = (error) => {
    let errorStr = error;

    if (errorStr === 'must be equal to one of the allowed values') {
      errorStr = 'You must select a answer';
    }

    if (errorStr === 'must be equal to constant') {
      errorStr = 'You must select a answer';
    }

    if (errorStr === 'must be string') {
      errorStr = 'You must provide a answer';
    }

    if (errorStr === 'must match format "email"') {
      errorStr = 'You must provide a valid eMail address';
    }

    if (errorStr === 'must match format "mobilenumber"') {
      errorStr = 'You must provide a valid mobile phone number';
    }

    if (errorStr === 'must match format "medicarecard"') {
      errorStr = 'You must provide your Medicare card number';
    }

    if (errorStr === 'must match format "medicareexp"') {
      errorStr = 'You must provide your Medicare card expiry date';
    }

    if (errorStr === 'must match format "medicareref"') {
      errorStr =
        'You must provide your Medicare card reference number. This can be found on the left our your name.';
    }

    return errorStr;
  };

  const setFormFieldNotes = (fieldName, value) => {
    setForm((prev) => {
      const notes = { ...prev.notes, [fieldName]: value };

      return {
        ...prev,
        notes,
      };
    });
  };

  const setFormField = (fieldName, value) => {
    setForm((prev) => {
      const values = { ...prev.values, [fieldName]: value };
      const valid = validationSchema(values);
      const errors = Object.fromEntries(
        (validationSchema?.errors ?? []).map((error) => {
          const idPath = error.instancePath.substr(1).split('/');
          return [idPath, processError(error.message)];
        }),
      );

      if (onSetFormField) {
        onSetFormField(fieldName, value);
      }

      return {
        ...prev,
        values,
        valid,
        errors,
      };
    });
  };

  const onPress = () => {
    if (loading) return;

    const valid = validationSchema(form.values);
    const errors = Object.fromEntries(
      (validationSchema?.errors ?? []).map((error) => {
        const idPath = error.instancePath.substr(1).split('/');

        return [idPath, processError(error.message)];
      }),
    );


    setForm((prev) => ({
      ...prev,
      valid,
      errors,
      submitCount: prev.submitCount + 1,
    }));

    if (!valid) {
      log('Form not valid', errors);

      alert('Please check the form and try again');

      trackEvent('form_error', { errors });

      return;
    }

    if (onSubmit) onSubmit(form.form.id, form.values, form.notes);
  };

  return { form, setFormField, setFormFieldNotes, onPress, setupForm, shouldShowField };
};
