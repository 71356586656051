import { GrowthBook } from '@growthbook/growthbook-react';
import { isDev } from '../hooks/useIsDev';
import mixpanel from 'mixpanel-browser';
import { LocalStorageStickyBucketService } from '@growthbook/growthbook';

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: isDev() ? 'sdk-vQO11QOxmBjSkyVV' : 'sdk-kPlUXjpdFhhhvzML',
  enableDevMode: isDev(),
  stickyBucketService: new LocalStorageStickyBucketService(),
  trackingCallback: (experiment, result) => {
    if (isDev()) {
      console.log('Viewed Experiment', {
        experimentId: experiment.key,
        variationId: result.key,
      });
    }

    mixpanel.track('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      $source: 'growthbook',
    });
  },
});
