import React, { useState } from 'react';
import { VStack, Text, Input } from 'native-base';
import { FormFieldOptions } from './FormFieldOptions';
import { FormFieldText } from './FormFieldText';
import { FormFieldDate } from './FormFieldDate';
import { FormFieldAddress } from './FormFieldAddress';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { FormFieldDropdown } from './FormFieldDropdown';
import { FormFieldMarkdown } from './FormFieldMarkdown';
import { FormFieldSignature } from './FormFieldSignature';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { FormFieldDateRange } from './FormFieldDateRange';
import { FormFieldCheckbox } from './FormFieldCheckbox';
import { Icon } from '../../Common/components/Icon';
import { FormFieldTextMedication } from './FormFieldTextMedication';
import { FormFieldPriorMedications } from './FormFieldPriorMedications';
import { FormFieldTurnstile } from './FormFieldTurnstile';

export function FormField({
  field,
  error,
  loading,
  value,
  notes,
  setFormFieldNotes,
  setFormField,
  submitCount,
  labelColor,
}) {
  const isValid = value && !error;

  const [focused, setFocused] = useState(false);

  const isError = (value && error && !focused) || (submitCount > 0 && error);

  const fieldComponent = () => {
    if (field.type === 'markdown') {
      return <FormFieldMarkdown text={field.markdown} />;
    }

    if (field.type === 'daterange') {
      return (
        <FormFieldDateRange
          value={value}
          loading={loading}
          placeholder={field.placeholder}
          id={field.id}
          large={field.large}
          labelColor={labelColor}
          isError={isError}
          subType={field.subType}
          setFocused={setFocused}
          isValid={isValid}
          autocomplete={field?.autocomplete}
          setFormField={setFormField}
        />
      );
    }

    if (field.type === 'signature') {
      return (
        <FormFieldSignature
          value={value}
          error={error}
          isError={isError}
          id={field.id}
          setFormField={setFormField}
        />
      );
    }

    if (field.type === 'options') {
      return (
        <FormFieldOptions
          options={field.values}
          value={value}
          error={error}
          isError={isError}
          multi={field.multi ?? false}
          loading={loading}
          id={field.id}
          setFormField={setFormField}
        />
      );
    }

    if (field.type === 'checkbox') {
      return (
        <FormFieldCheckbox
          value={value}
          error={error}
          isError={isError}
          isValid={isValid}
          loading={loading}
          label={field.name}
          id={field.id}
          setFormField={setFormField}
        />
      );
    }

    if (field.type === 'dropdown') {
      return (
        <FormFieldDropdown
          options={field.options}
          value={value}
          error={error}
          isError={isError}
          isValid={isValid}
          loading={loading}
          id={field.id}
          setFormField={setFormField}
        />
      );
    }

    if (field.type === 'scriptselection') {
      return (
        <FormFieldPriorMedications
          value={value}
          loading={loading}
          placeholder={field.placeholder}
          id={field.id}
          labelColor={labelColor}
          isError={isError}
          setFocused={setFocused}
          isValid={isValid}
          setFormField={setFormField}
        />
      );
    }

    if (field.id === 'medication' && field.type === 'text') {
      return (
        <FormFieldTextMedication
          value={value}
          loading={loading}
          placeholder={field.placeholder}
          id={field.id}
          labelColor={labelColor}
          isError={isError}
          setFocused={setFocused}
          isValid={isValid}
          setFormField={setFormField}
        />
      );
    }

    if (field.type === 'text') {
      return (
        <FormFieldText
          value={value}
          loading={loading}
          placeholder={field.placeholder}
          id={field.id}
          large={field.large}
          labelColor={labelColor}
          isError={isError}
          subType={field.subType}
          setFocused={setFocused}
          isValid={isValid}
          autocomplete={field?.autocomplete}
          setFormField={setFormField}
        />
      );
    }

    if (field.type === 'address') {
      return (
        <FormFieldAddress
          value={value}
          loading={loading}
          id={field.id}
          large={field.large}
          isError={isError}
          setFocused={setFocused}
          subType={field.subType}
          isValid={isValid}
          autocomplete={field?.autocomplete}
          setFormField={setFormField}
        />
      );
    }

    if (field.type === 'date') {
      return (
        <FormFieldDate
          value={value}
          id={field.id}
          isError={isError}
          setFocused={setFocused}
          isValid={isValid}
          loading={loading}
          autocomplete={field?.autocomplete}
          setFormField={setFormField}
        />
      );
    }

    return null;
  };

  if (field.type === 'turnstile') {
    return <FormFieldTurnstile setFormField={setFormField} />;
  }

  return (
    <VStack space={2}>
      <Text color={'formLabel'} fontSize={'sm'} color={labelColor} fontWeight={600}>
        {field.name}
      </Text>

      {fieldComponent()}

      {(field.help || field.explainer) && (
        <Text fontSize={'xs'} color={'formHelp'}>
          <Icon icon={faInfoCircle} size={'sm'} /> {field.help ?? field.explainer}
        </Text>
      )}

      {field.notes && value && (
        <>
          <Text color={'formHelp'}>Additional Details/Notes</Text>
          <Input
            value={notes ?? ''}
            size="lg"
            bg={'white'}
            type={'text'}
            isDisabled={loading}
            style={{
              height: 80,
              opacity: loading ? 0.5 : 1,
            }}
            onChangeText={(text) => setFormFieldNotes(field.id, text)}
          />
        </>
      )}

      {isError && (
        <Text color={'red.500'} fontWeight={600}>
          <Icon icon={faExclamationCircle} /> {error}
        </Text>
      )}
    </VStack>
  );
}
