import { useEffect } from "react";
import { useAnalytics } from "./useAnalytics";
import { useRecoilValue } from "recoil";
import { authenticationStateAtom } from "../state/authenticationState";

export const useCustomerIO = () => {
    const { identifyUserByEmail } = useAnalytics();
    const { user } = useRecoilValue(authenticationStateAtom);

    useEffect(() => {
        if (user?.email) {
          identifyUserByEmail(user.email, {});
        }
      }, [user?.email])
}