import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useLocation, useNavigate } from '../../../Common/components/Navigation';
import { Badge, Box, HStack, Pressable, Text, VStack } from 'native-base';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { usePatientOrderingEnabled } from '../../../PatientOrder/hooks/usePatientOrderingEnabled';
import { useMessagesCustomerUnread } from '../../../Messages/hooks/useMessagesCustomerUnread';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faShoppingBasket } from '@fortawesome/pro-solid-svg-icons/faShoppingBasket';
import { faMessaging } from '@fortawesome/pro-solid-svg-icons/faMessaging';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import React, { memo, useEffect } from 'react';
import { Icon } from '../../../Common/components/Icon';
import { SafeAreaView } from 'react-native';
import { useBooleanFlag } from '../../../Common/hooks/useBooleanFlag';
import { useIsMobile } from '../../../Common/hooks/useIsMobile';

function TabButton({
  badge,
  title,
  icon,
  color,
  path,
}: {
  badge?: boolean;
  title: string;
  color?: string;
  icon: IconDefinition;
  path: string;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const active = pathname === path;

  return (
    <Pressable
      onPress={() => {
        navigate(path);
      }}
      flex={1}
    >
      <VStack
        space={2}
        pt={3}
        opacity={active ? 1 : 0.75}
        justifyItems={'center'}
        alignItems={'center'}
      >
        <Icon color={color} size={'xl'} icon={icon} />

        <Text color={color} textAlign={'center'} fontSize={'xs'} fontWeight={active ? 600 : null}>
          {title}
        </Text>
      </VStack>

      {badge && (
        <Box
          alignItems={'center'}
          justifyContent={'center'}
          color={'white'}
          w={7}
          h={7}
          bg={'#e74c3c'}
          borderRadius={14}
          position={'absolute'}
          top={-7}
          right={-7}
        >
          <Icon icon={faBell} color={'white'} size={'md'} />
        </Box>
      )}
    </Pressable>
  );
}

export const Tabs = memo(({ background, color }: { background?: string; color?: string }) => {
  const { unread } = useMessagesCustomerUnread();

  useEffect(() => {
    try {
      Intercom('update', {
        hide_default_launcher: true,
      });
    } catch (e) {}
  }, []);

  return (
    <SafeAreaView style={{ backgroundColor: background }}>
      <HStack space={4}>
        <TabButton color={color} icon={faHome} path={'/profile'} title={'Home'} />

        <TabButton color={color} icon={faShoppingBasket} path={'/profile/order'} title={`Order`} />

        <TabButton
          color={color}
          icon={faMessaging}
          path={'/profile/messages'}
          title={`Messages`}
          badge={unread}
        />

        <TabButton
          color={color}
          icon={faCalendar}
          path={'/profile/appointments'}
          title={'Appointments'}
        />
      </HStack>
    </SafeAreaView>
  );
});
